import React from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import { Card, CardBody, Col, Form } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { TOKEN } from '../../_helpers/token';
import * as moment from 'moment';
import { Link } from 'react-router-dom';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import { DateRangePicker } from 'react-date-range';
import { FormControl } from 'react-bootstrap'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Modal from 'react-modal';
import './style.css';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { startAsyncValidation } from 'redux-form';

export class IncomingMessages extends React.Component {
    constructor(props) {
        super(props)
        this._onButtonClick = this._onButtonClick.bind(this);
        this._onButtonClick2 = this._onButtonClick2.bind(this);
        this.getDataUpdate = this.getDataUpdate.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleFromDateUpdate = this.handleFromDateUpdate.bind(this);
        this.handleToDateUpdate = this.handleToDateUpdate.bind(this);
        this.applyCallback = this.applyCallback.bind(this);

        let Sdate = moment(new Date()).format('YYYY-MM-DD');
        let Edate = moment(new Date()).format('YYYY-MM-DD');

        localStorage.setItem("STARTDATE", Sdate + " 00: 00: 00")

        localStorage.setItem("ENDDATE", Edate + " 23: 59: 59")



        this.columns = [


            {

                key: "msisdn",
                TrOnlyClassName: 'tsc',
                text: "Phone",
                className: "tsc",
                align: "left"
            },
            {
                key: "mshortcode",
                TrOnlyClassName: 'tsc',
                text: "Shortcode",
                className: "tsc",
                align: "left"
            },
            {
                key: "user_data",
                TrOnlyClassName: 'tsc',
                text: "Message",
                className: "tsc",
                align: "left"
            },
            // {
            //     key: "rate",
            //     TrOnlyClassName: 'tsc',
            //     text: "Rate",
            //     className: "tsc",
            //     align: "left"
            // },
            {
                key: "dates",
                TrOnlyClassName: 'tsc',
                text: "Date Created",
                className: "cell",
                align: "left"
            },

        ];
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50, 100],
            show_filter: false,
            show_pagination: true,
            pagination: 'advance',
            page_size: 10,
            filename: "restaurents",
            button: {
                excel: true,
                csv: true
            },
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Search table...",
                no_data_text: "No data was found",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            },

        }

        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            showError: false,
            isOpen: false,
            startDate: new Date(),
            endDate: new Date(),
            isShowError: false,
            selectionRange: {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection',
            },
            showComponent: false,
            showModals: false,
            showEdit: false,
            hideComponent: false,
            data: [],
        };

        let now = new Date();
        let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
        let end = moment(start).add(1, "days").subtract(1, "seconds");
        this.state = {
            start: start,
            end: end
        }




        this.extraButtons = [
            {
                className: "btn btn-primary buttons-pdf",
                title: "Export TEst",
                children: [
                    <span>
                        <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
                    </span>
                ],
                onClick: (event) => {
                    alert("dnhdh")
                    console.log(event);
                },
            },
            {
                className: "btn btn-primary buttons-pdf",
                title: "Export TEst",
                children: [
                    <span>
                        <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
                    </span>
                ],
                onClick: (event) => {
                    console.log(event);
                },
                onDoubleClick: (event) => {
                    console.log("doubleClick")
                }
            },
        ]

    }


    componentDidMount() {
        Modal.setAppElement('body');
        this.getData();
    }

    getData = (queryString = "") => {

        let SdateS = moment(new Date()).format('YYYY-MM-DD');
        let EdateS = moment(new Date()).format('YYYY-MM-DD');

        localStorage.setItem("STARTDATE", SdateS + " 00: 00: 00")

        localStorage.setItem("ENDDATE", EdateS + " 23: 59: 59")

        let Sdate = moment(new Date()).format('YYYY-MM-DD');
        let Edate = moment(new Date()).format('YYYY-MM-DD');



        this.setState({
            isLoading: true,
            valuedate: ""
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + TOKEN
            }
        };
        let url = baseURL + "messages?start_date=" + Sdate + " 00:00:00" + "&end_date=" + Edate + " 23:59:59&" + queryString
        // console.log("query", url);
        //  alert(url)
        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((branchResponse) => {
            this.setState({
                admins: branchResponse.data.data,
                isLoading: false,
            },
                function () {
                    var data = [];
                    console.log("BUGSSS", this.state.admins)

                    if (this.state.admins.length > 0 || this.state.admins.length === 0) {

                        for (let i = 0; i < this.state.admins.length; i++) {
                            this.setState({
                                total: branchResponse.data.total
                            })
                            let mail;
                            let mbalance;
                            let phone;
                            let index = { idx: i + 1 }
                            if (this.state.admins[i].email == null) {
                                mail = { mail: "_" };
                            } else {
                                mail = { mail: this.state.admins[i].email };
                            }

                            if (this.state.admins[i].balance === 0) {
                                mbalance = { mbalance: "0.0" };
                            } else {
                                mbalance = { mbalance: this.state.admins[i].balance };
                            }
                            let mshortcode;

                            mshortcode = { mshortcode: this.state.admins[i].shortcodes.shortcode };

                            let mrate = { rate: "KES.10" };

                            if (this.state.admins[i].msisdn === null) {
                                phone = { phone: "_" };
                            } else {
                                phone = { phone: this.state.admins[i].msisdn };
                            }

                            let date = { dates: moment.utc(this.state.admins[i].created_at).format('YYYY-MM-DD HH:mm:ss') };

                            data.push(Object.assign(date, mail, phone, mrate, index, mshortcode, this.state.admins[i]));

                            this.setState({
                                data: data
                            })
                            console.log("bugs", data);

                        }
                    }
                })
        })).catch(error => {
            console.log('bayoo', error.response)

        })
    }


    applyCallback(startDate, endDate) {
        localStorage.setItem("STARTDATE", startDate)
        localStorage.setItem("ENDDATE", endDate)


        let Sdate1 = moment(startDate).format('YYYY/MM/DD HH:mm:ss');
        let Edate2 = moment(endDate).format('YYYY/MM/DD HH:mm:ss');
        this.setState({
            valuedate: Sdate1 + " " + Edate2
        })

        this.getData2(startDate, endDate);
    }

    getData2 = (startDate, endDate, queryString = "") => {


        // alert("d")
        let Sdate = moment(startDate).format('YYYY-MM-DD HH:mm:ss');
        let Edate = moment(endDate).format('YYYY-MM-DD HH:mm:ss');


        let Sdate1 = moment(startDate).format('YYYY/MM/DD HH:mm:ss');
        let Edate2 = moment(endDate).format('YYYY/MM/DD HH:mm:ss');

        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + TOKEN
            }
        };
        let url = baseURL + "messages?start_date=" + Sdate + "&end_date=" + Edate + "&" + queryString
        console.log("query", url);

        //    alert(url)
        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((branchResponse) => {
            this.setState({
                admins: branchResponse.data.data,
                isLoading: false,
            },
                function () {
                    var data = [];
                    console.log("BUGSSS", this.state.admins)

                    if (this.state.admins.length > 0 || this.state.admins.length === 0) {

                        for (let i = 0; i < this.state.admins.length; i++) {
                            this.setState({
                                total: branchResponse.data.total
                            })
                            let mail;
                            let mbalance;
                            let phone;
                            let index = { idx: i + 1 }
                            if (this.state.admins[i].email == null) {
                                mail = { mail: "_" };
                            } else {
                                mail = { mail: this.state.admins[i].email };
                            }

                            if (this.state.admins[i].balance === 0) {
                                mbalance = { mbalance: "0.0" };
                            } else {
                                mbalance = { mbalance: this.state.admins[i].balance };
                            }
                            let mshortcode;
                            let mrate = { rate: "KES.10" };

                            mshortcode = { mshortcode: this.state.admins[i].shortcodes.shortcode };


                            if (this.state.admins[i].msisdn === null) {
                                phone = { phone: "_" };
                            } else {
                                phone = { phone: this.state.admins[i].msisdn };
                            }

                            let date = { dates: moment.utc(this.state.admins[i].created_at).format('YYYY-MM-DD HH:mm:ss') };

                            data.push(Object.assign(date, mrate, mail, phone, index, mshortcode, this.state.admins[i]));

                            this.setState({
                                data: data
                            })
                            console.log("bugs", data);

                        }
                    }

                })
        })).catch(error => {
            console.log('bayoo', error.response)

        })
    }



    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column")
                    + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        // this.getData(queryString);

        this.getData2(localStorage.getItem("STARTDATE"), localStorage.getItem("ENDDATE"), queryString)
    }



    getDataUpdate() {
        let queryString = ("filter_value=&page_number=0&page_size=10&sort_order=false")
        this.getData()
    }

    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,
            showModal: false,
        });
    }

    _onButtonClick2() {
        this.setState({
            showComponent: true,
            hideComponent: true,
            showModal: false,
            showModals: false
        });
    }

    onEdit(record) {
        this.setState({
            showComponent: false,
            hideComponent: true,
            showEdit: true,
        }, function () {
            // localStorage.setItem("question_id", this.state.survey_id)
        });

    }

    onAnswers(record) {
        localStorage.setItem("question_id", this.state.survey_id)

        this.setState({
            showComponent: false,
            hideComponent: true,
            showModal: true,
        }, function () {
        });

    }

    handleFromDateUpdate(date) {
        this.setState({
            startDate: date.date
        })
    }
    handleToDateUpdate(date) {
        this.setState({
            endDate: date.date
        })
    }


    handleSelect(ranges) {
        this.setState({
            selectionRange: {
                startDate: ranges.selection.startDate,
                endDate: ranges.selection.endDate,
                key: 'selection',
            }
        })
        //    alert(window.location.href)
        //     console.log("DATETIMR", JSON.stringify(this.state.selectionRange));
    }
    toggleModal = e => {
        this.setState({
            isOpen: true,
        });
    };

    closeModal = e => {
        this.setState({
            isOpen: false,
        });
    };

    closeModal2 = e => {
        this.getData2();
        this.setState({
            isOpen: false,
        });
    };


    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;
        const { showModal } = this.state;

        const { showModals } = this.state;

        let ranges = {
            "Today Only": [moment(this.state.start), moment(this.state.end)],
            "Yesterday Only": [
                moment(this.state.start).subtract(1, "days"),
                moment(this.state.end).subtract(1, "days")
            ],
            "3 Days": [moment(this.state.start).subtract(3, "days"), moment(this.state.end)],
            "5 Days": [moment(this.state.start).subtract(5, "days"), moment(this.state.end)],
            "1 Week": [moment(this.state.start).subtract(7, "days"), moment(this.state.end)],
            "2 Weeks": [moment(this.state.start).subtract(14, "days"), moment(this.state.end)],
            "1 Month": [moment(this.state.start).subtract(1, "months"), moment(this.state.end)],
            "1 Year": [moment(this.state.start).subtract(1, "years"), moment(this.state.end)]
        };
        let local = {
            "format": "DD-MM-YYYY HH:mm",
            "sundayFirst": false
        }
        return (
            <div style={{ marginTop: "-20px" }} >
                {!hideComponent && (
                    < Card >

                        <CardBody >
                            {/* DATERANGER picker */}
                            {/* <DateRangePicker
                                        ranges={[this.state.selectionRange]}
                                        showMonthArrow={false}
                                        onChange={this.handleSelect}
                                    /> */}
                            <div className="panel-body" >
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4> <b>Incoming Messages</b></h4><br />
                                    </div>
                                    <div className="col-6">
                                        <button className="float-right" title="refresh" style={{ background: "white", border: "0px" }}
                                            onClick={this.getDataUpdate}>
                                            <RefreshIcon />
                                        </button>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-6">


                                                    <DateTimeRangeContainer
                                                        ranges={ranges}
                                                        start={this.state.start}
                                                        end={this.state.end}
                                                        local={local}
                                                        // maxDate={maxDate}
                                                        applyCallback={this.applyCallback}>
                                                        <FormControl
                                                            id="formControlsTextB"
                                                            type="text"
                                                            value={this.state.valuedate}
                                                            label="Text"
                                                            placeholder="Filter by Date"
                                                        />
                                                    </DateTimeRangeContainer>
                                                </div>
                                                <div className="col-6 float-left">

                                                    <button className=" float-left btn btn-primary btn-sm" onClick={e => {
                                                        this.getData();
                                                        this.setState({
                                                            valuedate: "Filter by Date"
                                                        })
                                                    }} >Clear</button>
                                                    {/* <input id="input" type="text" readOnly /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                    </div>
                                </div><br />
                                {this.state.showError ? <div style={{ color: 'red' }}>
                                    {this.state.statusMessage}
                                </div> : null}
                                {this.state.isShowError ? (
                                    <div
                                        color="success"
                                        style={{ fontSize: "13px", color: "green" }}>
                                        {this.state.statusMessage}
                                    </div>

                                ) : null}
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.data}
                                    columns={this.columns}
                                    dynamic={true}
                                    id="tsc"
                                    extraButtons={this.extraButtons}
                                    loading={this.state.isLoading}
                                    total_record={this.state.total}
                                    onChange={this.tableChangeHandler} />
                            </div>
                        </CardBody>

                    </Card>
                )
                }
            </div>
        )
    }
}