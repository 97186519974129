import React, { Fragment } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { TOKEN } from '../../_helpers/token';
import { Link, Route } from 'react-router-dom';
import * as moment from 'moment';
import addUser from '../Groups/addUser';
import { confirm } from "../../shared/components/confirm";
import Alert from '../../shared/components/Alert';


export class Users extends React.Component {
    constructor(props) {
        super(props);
        this.submitNewUpdate = this.submitNewUpdate.bind(this);
        this._onButtonClick = this._onButtonClick.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);

        this.columns = [
            // {
            //     key: "id",
            //     TrOnlyClassName: 'tsc',
            //     text: "#",
            //     className: "tsc",
            //     align: "left"
            // },
            {
                key: "name",
                TrOnlyClassName: 'tsc',
                text: "Role",
                className: "tsc",
                align: "left"
            },
            {
                key: "username",
                TrOnlyClassName: 'tsc',
                text: "Username",
                className: "tsc",
                align: "left"
            },
            {
                key: "first_name",
                TrOnlyClassName: 'tsc',
                text: "First Name",
                className: "tsc",
                align: "left"
            },
            {
                key: "last_name",
                TrOnlyClassName: 'tsc',
                text: "Lastname",
                className: "tsc",
                align: "left"
            },
            {
                key: "email",
                TrOnlyClassName: 'tsc',
                text: "Email",
                className: "tsc",
                align: "left"
            },

            {
                key: "msisdn",
                TrOnlyClassName: 'tsc',
                text: "Phone No.",
                className: "tsc",
                align: "left"
            },

            {
                key: "dates",
                text: "Date Created",
                TrOnlyClassName: 'tsc',
                className: "tsc",
                align: "left"
            },
            {
                key: "action",
                text: "Options",
                TrOnlyClassName: 'cell',
                className: "cell",
                width: 250,
                sortable: false,
                cell: record => {
                    return (
                        <Fragment className="center" >
                            {/* <button className=" btn-primary"
                                title="Eith details"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.onEdit(record) }} >
                                <span className="fa fa-edit dt-icon-btn" > Edit </span>
                            </button> */}
                            <button className="btn btn-danger btn-sm"
                                title="Delete details"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.handleOnClick(record) }} > Remove
                            </button>



                        </Fragment>
                    );
                }
            }

        ];
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            show_filter: false,
            show_pagination: true,
            pagination: 'advance',
            page_size: 10,
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }
        }
        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            showError: false,
            isShowError: false,
            showComponent: false,
            hideComponent: false,
            data: [],
        };
    }
    componentDidMount() {
        this.getData();
    }

    async handleOnClick(record) {
        console.log("hoge!");
        if (await confirm("Are your sure?")) {
            this.onDelete(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }
    getData = (queryString = "") => {
        this.setState({
            isLoading: true,
        })
        // alert(TOKEN)
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": 'Bearer ' + TOKEN
            }
        };
        let url = baseURL + "users?" + queryString
        console.log("query", url);
        // alert(localStorage.getItem("customerID"))

        console.log("token", TOKEN)
        axios.all([
            axios.get(url, axiosConfig),
            axios.get(baseURL + "groups", axiosConfig),
        ]).then(axios.spread((branchResponse, groupResponse) => {
            this.setState({
                admins: branchResponse.data.data,
                groups: groupResponse.data.data,
                isLoading: false
            }, function () {
                var data = [];
                console.log("BUG", this.state.admins)

                for (let i = 0; i < this.state.admins.length || 0; i++) {
                    this.setState({
                        total: branchResponse.data.total
                    })

                    for (let j = 0; j < this.state.groups.length || 0; j++) {
                        if (this.state.admins[i].group_id === this.state.groups[j].id) {
                            let mail;
                            // eslint-disable-next-line
                            mail = { userID: this.state.admins[i].id };

                            if (this.state.admins[i].Amount == 0) {
                            } else {
                                mail = { mail: this.state.admins[i].Amount };
                            }
                            // eslint-disable-next-line
                            let date = { dates: moment(this.state.admins[i].date_created).format("YYYY-MM-DD") };
                            data.push(Object.assign(mail, date, this.state.admins[i], this.state.groups[j]));
                            this.setState({
                                data: data
                            })
                            console.log("bugs", data);
                        }
                    }
                }
            }
            );
        }))

    }
    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }

    editRecord(record) {
        console.log("Edit Record", record);
        this.setState({
            showModal: true,
            hideComponent: true,
        })
    }

    submitNewUpdate(e) {
        e.preventDefault();
        const userInputData = {
            "branch_name": this.state.branch_name,
            "branch_description": this.state.branch_description,
        }
        console.log("reecrd", userInputData);
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            }
        };
        console.log("DATA", this.state.admin);
        this.setState({ isLoading: true })

        axios.put(baseURL + "branches/" + this.state.admin, userInputData, config).then(response => {
            if (response.data.status) {
                this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false });
            } else {
                this.setState({ statusMessage: response.data.status_message, showError: true, isShowError: false, isLoading: false });
            }
        }, error => {
            this.setState({ statusMessage: JSON.stringify(error), isShowError: false, showError: true, isLoading: false });
        });
        this.setState({ school_name: '', school_code: '', postal_address: '' })

    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    onDelete(record) {

        console.log("Delete Record", record);
        axios.delete(baseURL + "users/" + record.id, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        }).then(response => {
            console.log("true", response.data)
            if (response.data.status) {
                this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData();
                        this.setState({ isShowError: false })
                    }, 2000);
                });
            } else {
                this.setState({ statusMessage: response.data.status_message, isShowError: false, showError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData();
                        this.setState({ isShowError: false, showError: false })
                    }, 2000);
                });
            }
        }, error => {
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
        });
    }

    onEdit(record) {
        this.setState({
            showComponent: false,
            hideComponent: true,
            showEdit: true,
            name: record.name,
            shortcode_id: record.shortcode_id,
            shortcode: record.shortcode
        }, function () {
            //  localStorage.setItem("", this.state.survey_id)
        });

    }

    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: false,

        });
    }

    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;
        // console.log("Load", isLoading);
        return (
            <div >
                {showComponent && (
                    < >
                        <addUser />
                    </>
                )}
                {!hideComponent && (


                    <>
                        < Col md={12} lg={12} >
                            < Card >
                                <CardBody >
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h4><b>List of Users</b></h4>
                                        </div>
                                        <div className="col-md-6">
                                            <Link to="/addusers">
                                                <Button className="pull-right" outline color="primary" onClick={this._onButtonClick}>
                                                    Add User
                                           </Button>
                                            </Link>

                                        </div>
                                    </div>
                                    {this.state.showError ? <div >

                                        <Alert color="danger" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>


                                    </div> : null}<br></br>

                                    {this.state.isShowError ? (
                                        <div>

                                            <Alert color="success" className="alert--colored" icon>
                                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                                </p>
                                            </Alert>

                                        </div>

                                    ) : null}<br></br>

                                    <ReactDatatable
                                        config={this.config}
                                        records={this.state.data}
                                        columns={this.columns}
                                        dynamic={true}
                                        id="tsc"
                                        loading={this.state.isLoading}
                                        total_record={this.state.total}
                                        onChange={this.tableChangeHandler} />
                                </CardBody>

                            </Card>
                        </Col>
                    </>

                )
                }
            </div>

        )
    }
}