import React, { Fragment } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import { Button, Card, CardBody, Col } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { TOKEN } from '../../_helpers/token';
import * as moment from 'moment';
import { Questions } from '../Qauestions/questions';
import { CreateSurvey } from './createSurvey';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { confirm } from "../../shared/components/confirm";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import TimePicker from 'react-time-picker/dist/entry.nostyle'

import { editSurvey } from './editSurvey';
import Alert from '../../shared/components/Alert';

export class Survey extends React.Component {
    constructor(props) {
        super(props);
        this._onButtonClick = this._onButtonClick.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);

        this._onButtonClick2 = this._onButtonClick2.bind(this);
        this.onQuestions = this.onQuestions.bind(this);
        this.onSubmitUpdate = this.onSubmitUpdate.bind(this);

        this.columns = [

            {
                key: "name",
                TrOnlyClassName: 'tsc',
                text: "Name",
                className: "tsc",
                align: "left"
            },

            // {
            //     key: "dates",
            //     TrOnlyClassName: 'tsc',
            //     text: "Date Created",
            //     className: "tsc",
            //     align: "left"
            // },
            {
                key: "start_datetime",
                TrOnlyClassName: 'tsc',
                text: "Start Date",
                className: "tsc",
                align: "left"
            },
            {
                key: "end_datetime",
                TrOnlyClassName: 'tsc',
                text: "End Date",
                className: "tsc",
                align: "left"
            },
            {
                key: "action",
                text: "Options",
                TrOnlyClassName: 'cell',
                className: "cell",
                width: 250,
                sortable: false,
                cell: record => {
                    return (
                        <Fragment className="center" >
                            <button className="btn btn-danger btn-sm"
                                title="Edit details"

                                onClick={
                                    () => { this.handleOnClick(record) }} >
                                Remove
                            </button>
                            <button type="button" className="btn btn-primary btn-sm"
                                title="Edit details"
                                onClick={
                                    () => { this.onEdit(record) }} >
                                Edit
                            </button>
                            <button className="btn btn-success btn-sm"
                                title="Questions details"
                                onClick={
                                    () => { this.onQuestions(record) }} >
                                Questions
                            </button>

                        </Fragment>
                    );
                }
            }
        ];


        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            page_size: 10,
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Search...",
                no_data_text: "No data was found",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }

        }
        this.state = {
            admins: [],
            isLoading: true,
            showModals: false,
            showError: false,
            isShowError: false,
            showComponent: false,
            showSurvey: false,
            start_date: new Date(),
            end_date: new Date(),
            hideComponent: false,
            data: [],
            queryString: "",
        };
    }


    async handleOnClick(record) {
        console.log("hoge!");
        if (await confirm("Are your sure?")) {
            this.onDelete(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData = (queryString = "") => {
        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + TOKEN
            }
        };
        let url = baseURL + "surveys?" + queryString
        this.setState({ queryString: queryString });
        console.log("queryString", queryString);

        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((branchResponse) => {
            this.setState({
                admins: branchResponse.data.data,
                isLoading: false,
            },
                function () {
                    var data = [];
                    // console.log("BUG", this.state.admins)

                    if (this.state.admins.length > 0 || this.state.admins.length === 0) {
                        this.setState({
                            total: branchResponse.data.total
                        })
                        let index;
                        for (let x = 0; x < branchResponse.data.total; x++) {
                            index = { idx: x + 1 }

                            let dataArray = [];
                            dataArray.push(index);
                            this.setState({
                                dataArray: dataArray
                            }, function () {
                                localStorage.setItem("DATAARRAY", JSON.stringify(dataArray))
                            })
                            console.log("BUG", dataArray)

                        }

                        for (let i = 0; i < this.state.admins.length; i++) {
                            // if (this.state.queryString === "") {

                            // } else {
                            index = { idx: (2 * (i * 1)) }
                            // }
                            let mail;
                            let mbalance;
                            let phone;
                            // y = 0 || this.state.total;
                            if (this.state.admins[i].email == null) {
                                mail = { mail: "_" };
                            } else {
                                mail = { mail: this.state.admins[i].email };
                            }

                            if (this.state.admins[i].balance === 0) {
                                mbalance = { mbalance: "0.0" };
                            } else {
                                mbalance = { mbalance: this.state.admins[i].balance };
                            }
                            if (this.state.admins[i].msisdn === null) {
                                phone = { phone: "_" };
                            } else {
                                phone = { phone: this.state.admins[i].msisdn };
                            }
                            let date = { dates: moment.utc(this.state.admins[i].created_at).format('YYYY-MM-DD') };
                            data.push(Object.assign(date, mail, phone, index, mbalance, this.state.admins[i]));
                            this.setState({
                                data: data
                            })
                            console.log("bugs", data);
                        }

                    }
                })
        })).catch(error => {
            console.log('bayoo', error.response)

        })
    }



    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }

    onDelete(record) {
        console.log("Delete Record", record);
        axios.delete(baseURL + "surveys/" + record.survey_id, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        }).then(response => {
            console.log("true", response.data)
            if (response.data.status) {
                this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData();
                        this.setState({ isShowError: false, statusMessage: "" })
                    }, 2000);
                });
            } else {
                this.getData();
                this.setState({ statusMessage: response.data.status_message, isShowError: false, showError: true, isLoading: false });
                window.setTimeout(() => {
                    this.getData();
                    this.setState({ showError: false, statusMessage: "" })
                }, 2000);
            }
        }, error => {
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
        });
    }

    onQuestions(record) {
        localStorage.setItem("survey_id", record.survey_id)
        localStorage.setItem("survey_name", record.name)

        this.setState({
            showComponent: true,
            hideComponent: true,
            showModal: false,
            survey_id: record.survey_id
        });
    }

    onEdit(record) {
        this.setState({
            showComponent: false,
            hideComponent: true,
            showModals: true,
            showSurvey: false,
            name: record.name,
            survey_id: record.survey_id
        });
    }


    onSubmitUpdate(e) {
        e.preventDefault();
        let Sdate = moment(this.state.start_date).format("yyyy-MM-DD HH:mm:ss");
        let Edate = moment(this.state.end_date).format("yyyy-MM-DD HH:mm:ss");
        let formData = {
            "name": this.state.name,
            "shortcode_id": this.state.shortcode_id,
            "start_datetime": Sdate,
            "end_datetime": Edate
        }
        // let formData = {
        //     "name": this.state.name
        // }
        console.log("DATA", JSON.stringify(formData))
        this.setState({ isLoading: true });

        axios.put(baseURL + 'surveys/' + this.state.survey_id, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        })
            .then((response) => {
                console.log("bayo", response)
                // eslint-disable-next-line
                if (response.data.status == true) {
                    console.log("records", formData)

                    this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false });
                    setTimeout(() => {
                        this.setState({ name: "", isShowError: false, isLoading: false });
                        this._onButtonClick2()
                        this.getData()

                    }, 2000);


                } else {

                    this.setState({ statusMessage: response.data.status_message, isShowError: false, showError: false, isLoading: false });

                    // window.setTimeout(function () {
                    //     window.location.href = "/session";
                    // }, 2000);
                }

            })
            .catch(error => {
                console.log('bayoo', error.response)
                this.setState({
                    showError: true,
                    isShowError: false,
                    statusMessage: error.response.data.status_message,
                    submitted: true,
                    isLoading: false
                }
                    , function () {
                        // console.log('data', error.response.status_message)
                    });
            });

    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    _onButtonClick() {
        // window.location.href = "/createSurvey"
        this.setState({
            showComponent: false,
            showSurvey: true,
            hideComponent: true,
            showModals: false
        });
    }

    _onButtonClick2() {
        this.setState({
            showComponent: false,
            hideComponent: false,
            showSurvey: false,
            showModals: false
        });
    }


    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;
        const { showModals, showSurvey } = this.state;
        return (
            <div >
                {!hideComponent && (
                    < >
                        < Col md={12} lg={12} >
                            < Card >
                                <CardBody >
                                    <div className="panel-body" >
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h4><b>List of Surveys</b></h4>
                                            </div>
                                            <div className="col-md-6">
                                                <div class="text-right">
                                                    <Link to="/createSurvey">
                                                        <Button color="primary" onClick={this._onButtonClick} outline>Create Surveys
                                                      </Button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.showError ? <div >

                                            <Alert color="danger" className="alert--colored" icon>
                                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                                </p>
                                            </Alert>


                                        </div> : null}<br></br>

                                        {this.state.isShowError ? (
                                            <div>

                                                <Alert color="success" className="alert--colored" icon>
                                                    <p><span className="bold-text">{this.state.statusMessage}</span>
                                                    </p>
                                                </Alert>

                                            </div>

                                        ) : null}<br></br>
                                        <ReactDatatable
                                            config={this.config}
                                            records={this.state.data}
                                            columns={this.columns}
                                            dynamic={true}
                                            id="tsc"
                                            tFooterClassName="tsc"
                                            loading={this.state.isLoading}
                                            total_record={this.state.total}
                                            onChange={this.tableChangeHandler} />
                                    </div>
                                </CardBody>

                            </Card>
                        </Col>
                    </>
                )}
                {showComponent && (
                    <div >
                        <Questions />
                    </div>
                )}
                {showSurvey && (
                    <div >
                        <createSurvey />
                    </div>
                )}
                {showModals && (
                    < >
                        <Col md={12} lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="row">
                                        <div className="col-md-6 ">
                                            <h4>Edit Survey</h4><br />
                                        </div>
                                        <div className="col-md-6">
                                            <Button className="pull-right" onClick={this._onButtonClick2}
                                                color="primary" outline>Surveys</Button>

                                        </div>
                                    </div>
                                    {this.state.showError ? <div >

                                        <Alert color="danger" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>


                                    </div> : null}<br></br>

                                    {this.state.isShowError ? (
                                        <div>

                                            <Alert color="success" className="alert--colored" icon>
                                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                                </p>
                                            </Alert>

                                        </div>

                                    ) : null}<br></br>
                                    <form className="col-md-8 offset-md-2" onSubmit={this.onSubmitUpdate}>

                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Survey Name</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="text" className="form-control input-md"
                                                        name="name" required placeholder="Enter Survey Name"
                                                        value={this.state.name} onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">

                                            <div className="row">
                                                <div className="col-md-2">
                                                    <Form.Label>Start date</Form.Label>
                                                </div>
                                                <div className="col-md-10 customDatePickerWidth">
                                                    <DatePicker
                                                        selected={this.state.start_date}
                                                        label="DatePicker Label"
                                                        dateFormat="yyyy-MM-dd HH:mm:ss"
                                                        className="form-control"
                                                        showTimeSelect
                                                        timeIntervals={1}
                                                        id="input"
                                                        onChange={value => this.setState({ start_date: value })} />


                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">

                                            <div className="row">
                                                <div className="col-md-2">
                                                    <Form.Label>End date</Form.Label>
                                                </div>
                                                <div className="col-md-10 customDatePickerWidth">
                                                    <DatePicker
                                                        selected={this.state.end_date}
                                                        label="DatePicker Label"
                                                        dateFormat="yyyy-MM-dd HH:mm:ss"
                                                        className="form-control"
                                                        showTimeSelect
                                                        timeIntervals={1}
                                                        id="input"
                                                        onChange={value => this.setState({ end_date: value })} />


                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="form-group">
                                            <br />
                                            <Button type="submit" color="primary" outline className="btn-paypal btn-lg pull-right" >
                                                {this.state.isLoading ? "Please Wait..." : "Submit"}

                                            </Button>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </>
                )
                }
            </div>
        )
    }
}