
import React, { Fragment } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import { Button, Card, CardBody, Col } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { ROLE, TOKEN } from '../../_helpers/token';
import * as moment from 'moment';
import { Survey } from '../Survey/survey';
import { Answers } from '../Answers/answers';
import { Link } from 'react-router-dom';
import QuestionCreate from './createQuestion';
import Select from 'react-select';
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import TimePicker from 'react-time-picker/dist/entry.nostyle'
import { confirm } from "../../shared/components/confirm";
import Alert from '../../shared/components/Alert';



export class Questions extends React.Component {
    constructor(props) {
        super(props)
        this._onButtonClick = this._onButtonClick.bind(this);
        this._onButtonClick2 = this._onButtonClick2.bind(this);
        this._onButtonClick3 = this._onButtonClick3.bind(this);
        this._onButtonClick5 = this._onButtonClick5.bind(this);
        this.onSubmitNewUpdatws = this.onSubmitNewUpdatws.bind(this);

        this.handleOnClick = this.handleOnClick.bind(this);
        this.handleOnClick1 = this.handleOnClick1.bind(this);

        this.columns = [
            // {
            //     key: "idx",
            //     TrOnlyClassName: 'tsc',
            //     text: "#",
            //     className: "tsc",
            //     align: "left"
            // },
            // {
            //     key: "priority",
            //     TrOnlyClassName: 'tsc',
            //     text: "Priority",
            //     className: "tsc",
            //     align: "left"
            // },
            {
                key: "question",
                TrOnlyClassName: 'tsc',
                text: " Question",
                className: "tsc",
                altrueign: "left"
            },
            {
                key: "type",
                TrOnlyClassName: 'tsc',
                text: "Type",
                className: "tsc",
                align: "left"
            },
            // {
            //     key: "total_feedbacks",
            //     TrOnlyClassName: 'tsc',
            //     text: "Total Feedbacks",
            //     className: "tsc",
            //     align: "left"
            // },


            // {
            //     key: "ttl",
            //     TrOnlyClassName: 'tsc',
            //     text: "TTL",
            //     className: "tsc",
            //     align: "left"
            // },
            {
                key: "status",
                TrOnlyClassName: 'tsc',
                text: "Status",
                className: "tsc",
                align: "left",
                cell: record => {
                    return (
                        <Fragment className="center" >
                            {record.status === 'APPROVED' ?
                                <span class="badge-success" style={{ borderRadius: "5px", padding: "2px", fontSize: "15px" }}>
                                    {record.status}
                                </span>
                                : null}

                            {record.status === 'REJECTED' ?
                                <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px", fontSize: "15px" }}>
                                    {record.status}
                                </ span>
                                : null}
                            {record.status === 'PENDING' ?
                                <span class="badge-info" style={{ borderRadius: "5px", padding: "2px", fontSize: "15px" }}>
                                    {record.status}
                                </span>
                                : null}

                        </Fragment >
                    );
                }
            },
            {
                key: "action",
                text: "Options",
                TrOnlyClassName: 'cell',
                className: "cell",
                width: 250,
                sortable: false,
                cell: record => {
                    return (
                        <Fragment className="center" >
                            {ROLE == '2' ?
                                record.status === 'PENDING' ?
                                    <button className="btn btn-info btn-sm"
                                        title="Eith details"
                                        style={
                                            { marginRight: '10px' }}
                                        onClick={
                                            () => { this.handleOnClick1(record) }} >
                                        Approve
                                </button>
                                    : null
                                : null}
                            <button className="btn btn-primary  btn-sm"
                                title="Eith details"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.onEdit(record) }} >
                                Edit
                            </button>
                            <button className="btn btn-danger  btn-sm"
                                title="Delete details"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.handleOnClick(record) }} >
                                Remove                            </button>
                            <button className="btn btn-success btn-sm"
                                title="Questions details"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.onAnswers(record) }} >
                                Answers
                            </button>



                        </Fragment>
                    );
                }
            }
        ];
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            page_size: 10,
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Search...",
                no_data_text: "No data was found",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }

        }
        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            showError: false,
            showModal: false,
            isShowError: false,
            showComponent: false,
            showModals: false,
            _onShow: false,



            showEdit: false,
            type: [{
                name: 'Rating'
            }, {
                name: 'Closed-ended'
            }],

            hideComponent: false,
            data: [],
            priority: [{
                name: '1'
            }, {
                name: '2'
            },
            {
                name: '3'
            },
            {
                name: '4'
            },
            {
                name: '5'
            },
            {
                name: '6'
            },
            {
                name: '7'
            },
            {
                name: '8'
            },
            {
                name: '9'
            },
            {
                name: '10'
            }
            ],
        };
    }

    async handleOnClick(record) {
        console.log("hoge!");
        if (await confirm("Are your sure?")) {
            this.onDelete(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }

    async handleOnClick1(record) {
        console.log("hoge!");
        if (await confirm("Are your sure?")) {
            this.onApprove(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }



    handleChangeType = (event) => {
        this.setState({ selectedType: event.target.value });
    };

    componentDidMount() {
        this.getData();
    }
    // localStorage.setItem("survey_id", record.survey_id)
    // localStorage.setItem("survey_name", record.name)

    getData = (queryString = "") => {
        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + TOKEN
            }
        };
        let url = baseURL + "question?survey_id=" + localStorage.getItem("survey_id") + "&&" + queryString
        console.log("query", url);

        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((branchResponse) => {
            console.log("BUGggg", branchResponse)

            this.setState({
                admins: branchResponse.data.data,
                isLoading: false,
            },
                function () {
                    var data = [];
                    console.log("BUG", this.state.admins)

                    this.setState({
                        total: branchResponse.data.total
                    })

                    console.log("BUGsss", branchResponse.data.total)

                    if (branchResponse.status) {

                        for (let i = 0; i < this.state.admins.length; i++) {

                            let mail;
                            let mbalance;
                            let phone;
                            let index = { idx: i + 1 }
                            if (this.state.admins[i].email == null) {
                                mail = { mail: "_" };
                            } else {
                                mail = { mail: this.state.admins[i].email };
                            }

                            if (this.state.admins[i].balance === 0) {
                                mbalance = { mbalance: "0.0" };
                            } else {
                                mbalance = { mbalance: this.state.admins[i].balance };
                            }

                            if (this.state.admins[i].msisdn === null) {
                                phone = { phone: "_" };
                            } else {
                                phone = { phone: this.state.admins[i].msisdn };
                            }

                            let date = { dates: (moment.utc(this.state.admins[i].created_at).format('YYYY-MM-DD')) };

                            let startDate = { date2: (this.state.admins[i].start_date + " " + this.state.admins[i].start_time) }

                            let endDate = { date3: (this.state.admins[i].end_date + "  " + this.state.admins[i].end_time) }


                            data.push(Object.assign(date, mail, phone, index, mbalance, startDate, endDate, this.state.admins[i]));
                            this.setState({
                                data: data
                            })
                            console.log("bugs", data);

                        }
                    } else {
                        alert(branchResponse.data.status_message)
                        this.setState({
                            showComponent: true,
                            hideComponent: true,
                            showModal: false,
                        });

                    }

                })
        })).catch(error => {
            console.log('bayoo', error.response)

        })
    }

    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }


    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,
            showEdit: false,
            showModal: false,
            showModals: false
        });
    }

    _onButtonClick5() {
        this.setState({
            showComponent: false,
            hideComponent: false,
            showEdit: false,
            showModal: false,
            showModals: false
        });
    }

    _onButtonClick2() {
        this.setState({
            showComponent: false,
            hideComponent: true,
            showModal: false,
            showEdit: false,
            _onShow: false,
            showModals: true
        });
    }

    _onButtonClick3() {
        this.setState({
            showComponent: false,
            hideComponent: false,
            showModal: false,
            _onShow: false,
            showEdit: false,
            showModals: false
        });
    }

    onEdit(record) {
        this.setState({
            showComponent: false,
            hideComponent: true,
            questionsss: record.question_id,
            showModal: false,
            question: record.question,
            start_date: record.start_date,
            start_time: record.start_time,
            end_date: record.end_date,
            end_time: record.end_time,

            // showModal: false,
            // showModal: false,
            _onShow: false,
            showModal: false,
            showModals: false,
            showEdit: true,
        }, function () {
            // localStorage.setItem("question_id", this.state.survey_id)
        });

    }

    onSubmitNewUpdatws(e) {
        e.preventDefault();
        let ttl_new = (this.state.ttl * 60)

        let Sdate = moment.utc(this.state.start_date).format("yyyy-MM-DD");
        let Edate = moment.utc(this.state.end_date).format("yyyy-MM-DD");
        let Stime = moment(this.state.start_date).format("HH:mm:ss");
        let Etime = moment(this.state.end_date).format("HH:mm:ss");
        let formData = {
            "survey_id": localStorage.getItem("survey_id"),
            // "start_date": this.state.start_date,
            // "start_time": this.state.start_time,
            // "end_time": this.state.end_time,
            // "end_date": this.state.end_date,
            "ttl": ttl_new,
            "type": this.state.selectedType,
            "question": this.state.question,

        }
        console.log("DATA", JSON.stringify(formData))
        this.setState({ isLoading: true });
        axios.put(baseURL + 'question/' + this.state.questionsss, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        })
            .then((response) => {
                console.log('bayoo', response.data)

                if (response.data.status) {
                    this.setState({ statusMessage: response.data.status_message, showError: false, isShowError: true, isLoading: false });
                    window.setTimeout(() => {
                        this.setState({ isShowError: false });
                        this._onButtonClick5()
                        this.getData()
                    }, 2000);
                } else {
                    this.setState({ statusMessage: response.data.status_message, showError: true, isShowError: false, isLoading: false });
                }
            })
            .catch((error) => {
                console.log('bayoo', error)
                this.setState({ statusMessage: error.response.data.status_message, showError: true, isShowError: false, isLoading: false });
            })
    }


    onDelete(record) {

        console.log("Delete Record", record);
        axios.delete(baseURL + "question/" + record.question_id, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        }).then(response => {
            console.log("true", response.data)
            if (response.data.status) {
                this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData();
                        this.setState({ isShowError: false })
                    }, 2000);
                });
            } else {
                this.setState({ statusMessage: response.data.status_message, isShowError: false, showError: true, isLoading: false });
                window.setTimeout(() => {
                    this.getData();
                    this.setState({ isShowError: false })
                }, 2000);
            }
        }, error => {
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
            window.setTimeout(() => {
                this.getData();
                this.setState({ isShowError: false })
            }, 2000);
        });
    }


    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    onApprove(record) {
        let body = {
            status: "APPROVED"
        }
        axios.put(baseURL + "question/" + record.question_id + "/approval", body, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        }).then(response => {
            console.log("true", response.data)
            if (response.data.status) {
                this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData();
                        this.setState({ isShowError: false })
                    }, 2000);
                });
            } else {
                this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false });
                window.setTimeout(() => {
                    this.getData();
                    this.setState({ isShowError: false })
                }, 2000);
            }
        }, error => {
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
            window.setTimeout(() => {
                this.getData();
                this.setState({ isShowError: false })
            }, 2000);
        });
    }


    onAnswers(record) {
        localStorage.setItem("question_id", record.question_id)
        localStorage.setItem("type_question", record.type)

        this.setState({
            showComponent: false,
            hideComponent: true,
            showModal: true,
        }, function () {
        });

    }
    handleChangeRegion = (event) => {
        this.setState({ selectedPriority: event.target.value });
    };
    ReactTypes() {
        return (this.state.priority && (this.state.priority.length > 0 || this.state.priority.length === 0) &&
            this.state.priority.map((countyItem, i) =>
                ({ label: countyItem.name, value: countyItem.name })))
    }

    _onShow(record) {
        this.setState({
            showComponent: false,
            hideComponent: true,
            editShow: true,
            acceptable_answers: record.acceptable_answers,
            answer: record.answer,
            answer_id: record.id,
            showModal: false,
        });
    }

    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;
        const { showModal } = this.state;
        const { showModals } = this.state;
        const { showEdit } = this.state;
        return (
            <div >
                {!hideComponent && (
                    < >
                        < Col md={12} lg={12} >
                            < Card >
                                <CardBody >
                                    <div className="panel-body" >
                                        <div className="row">
                                            <Col md={12}>
                                                <h4><b>List of Questions</b> ({localStorage.getItem("survey_name")})</h4><br />
                                            </Col>

                                            <div className="col-md-6">
                                                <Button color="primary" onClick={this._onButtonClick} outline> Surveys
                                            </Button>                                            </div>
                                            <div className="col-md-6">
                                                <Link to="questionCreate">
                                                    <Button className="pull-right" color="primary
                                                    " onClick={this._onButtonClick2} outline>Create Questions
                                                </Button>
                                                </Link>
                                            </div>
                                        </div>
                                        <br />
                                        {this.state.showError ? <div >

                                            <Alert color="danger" className="alert--colored" icon>
                                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                                </p>
                                            </Alert>


                                        </div> : null}<br></br>

                                        {this.state.isShowError ? (
                                            <div>

                                                <Alert color="success" className="alert--colored" icon>
                                                    <p><span className="bold-text">{this.state.statusMessage}</span>
                                                    </p>
                                                </Alert>

                                            </div>

                                        ) : null}<br></br>

                                        <ReactDatatable
                                            config={this.config}
                                            records={this.state.data}
                                            columns={this.columns}
                                            dynamic={true}
                                            id="tsc"
                                            loading={this.state.isLoading}
                                            total_record={this.state.total}
                                            onChange={this.tableChangeHandler} />

                                    </div>
                                </CardBody>

                            </Card>
                        </Col>
                    </>
                )}
                {showComponent && (
                    < >
                        <Survey />
                    </>
                )}

                {showModal && (
                    < >
                        <Answers />
                    </>
                )}

                {showModals && (
                    < >
                        <QuestionCreate />
                    </>
                )}
                {showEdit && (
                    <>
                        <Col md={12} lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h4><b>Edit a Question</b></h4>
                                        </div>
                                        <div className="col-md-6">
                                            <Button className="pull-right" outline color="primary" onClick={this._onButtonClick5}>
                                                Questions
                                            </Button>

                                        </div>
                                    </div>

                                    {this.state.showError ? <div >

                                        <Alert color="danger" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>


                                    </div> : null}<br></br>

                                    {this.state.isShowError ? (
                                        <div>

                                            <Alert color="success" className="alert--colored" icon>
                                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                                </p>
                                            </Alert>

                                        </div>

                                    ) : null}<br></br>
                                    <form className="form" onSubmit={this.onSubmitNewUpdatws}>
                                        {/* <div className="col-md-8 offset-2">
                                            <Form.Group className="Focus-line" >
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <Form.Label>Type</Form.Label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <Form.Control
                                                            as="select"
                                                            className="form-control input-md"
                                                            placeholder="Select Type"
                                                            value={this.state.selectedType}
                                                            onChange={this.handleChangeType}
                                                            id="county_name"
                                                            option={this.selectedType}>
                                                            <option>Select Type</option>
                                                            {
                                                                this.state.type && this.state.type.length > 0 &&
                                                                this.state.type.map((regionItem, i) =>
                                                                    <option key={i}
                                                                        value={regionItem.name}>{regionItem.name}</option>)
                                                            }
                                                        </Form.Control>
                                                    </div>
                                                </div>

                                            </Form.Group>
                                        </div> */}
                                        <div className="col-md-8 offset-2">

                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label className="form-label">Question</label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <input id="input" type="text" className="form-control input-md"
                                                            name="question" required placeholder="Enter Qustion"
                                                            value={this.state.question} onChange={this.handleChange} />
                                                    </div></div>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-8 offset-2">
                                            <Form.Group className="Focus-line" >
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <Form.Label>Start date</Form.Label>
                                                    </div>
                                                    <div className="col-md-10 customDatePickerWidth">
                                                        <DatePicker
                                                            selected={this.state.start_date}
                                                            label="DatePicker Label"
                                                            dateFormat="yyyy-MM-dd HH:mm:ss"
                                                            className="form-control"
                                                            showTimeSelect
                                                            timeIntervals={1}
                                                            id="input"
                                                            onChange={value => this.setState({ start_date: value })} />


                                                    </div>
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-8 offset-2">
                                            <Form.Group className="Focus-line" >
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <Form.Label>End date</Form.Label>
                                                    </div>
                                                    <div className="col-md-10 customDatePickerWidth">
                                                        <DatePicker
                                                            selected={this.state.end_date}
                                                            label="DatePicker Label"
                                                            dateFormat="yyyy-MM-dd HH:mm:ss"
                                                            showTimeSelect
                                                            timeIntervals={1}
                                                            style={{ color: "black" }}
                                                            id="input"
                                                            onChange={value => this.setState({ end_date: value })} />
                                                    </div>

                                                </div>
                                            </Form.Group>
                                        </div> */}

                                        <div className="col-md-8 offset-2">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label className="form-label">Time to Live (Minutes)</label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <input id="input" type="number" className="form-control"
                                                            name="ttl" required placeholder='Enter Time to Live in Minutes'
                                                            value={this.state.ttl} onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br></br>
                                        <br />

                                        <div className="col-md-8 offset-2 form-group">
                                            <br />
                                            <Button type="submit" color="primary" outline className="btn-paypal btn-lg pull-right" >
                                                {this.state.isLoading ? "Please Wait..." : "Submit"}

                                            </Button> &nbsp;&nbsp;&nbsp;
                                        </div>

                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </>
                )}
            </div>
        )
    }
}