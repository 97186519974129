import React, { Fragment } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import { Button, Card, CardBody, Col, Form, Row } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { TOKEN } from '../../_helpers/token';
import * as moment from 'moment';
import { Survey } from '../Survey/survey';
import { Answers } from '../Answers/answers';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Grps } from './Grps';
import { data } from 'jquery';
import Alert from '../../shared/components/Alert';
var Loader = require('react-loader');

const CheckboxField = ({ name, checked, onChange }) => {
    // alert(bayo)
    return (

        // <label style={{ fontSize: "1em", display: "block" }} >
        //     <input type="checkbox" style={{ verticalAlign: "middle" }} checked={checked} onChange={ev => onChange(ev.target.checked)} />
        //     <span style={{ verticalAlign: "middle" }}>{name}</span>

        // </label>
        // <div >

        //     <label style={{ fontSize: "1em" }} className="checkbox">{name}</label>
        //     <input type="checkbox" onChange={ev => onChange(ev.target.checked)} />

        // </div>


        <div className="col-md-12">
            <div className="row">
                <div className="col-md-2 offset-2">
                    <label style={{ fontSize: "1em" }} >{name}</label>
                </div>
                <div className="col-md-2 offset-2" style={{}}>
                    <input type="checkbox" checked={checked} onChange={ev => onChange(ev.target.checked)} />&nbsp;
                 </div>
            </div>
        </div>

    );
};
export class Roles extends React.Component {
    constructor() {
        super();

        this._onButtonClick3 = this._onButtonClick3.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.state = {
            Groups: [],
            options: [],
            data: [],
            bayo: [],
            loaded: false,
            showComponent: false,
            hideComponent: false,
        };
    }


    async componentDidMount() {
        this.state = {}
        const [
            countyResponse] = await Promise.all([
                axios.get(baseURL + 'group-roles?group_id=' + localStorage.getItem('groupadmin_id'), { headers: { "Authorization": `Bearer ` + TOKEN } }),
            ]);
        this.setState({
            isLoading: false,
            loaded: true,
            Groups: countyResponse.data.rows,
        })
        console.log("Groups", this.state.Groups)

        var dataArray = []
        var data = [];
        for (var i = 0; i < this.state.Groups.length; i++) {
            this.setState({
                ...this.state,
                options: [{
                    id: "can_read", name: "Read",
                    group_id: this.state.Groups[i].group_id,
                    object_id: this.state.Groups[i].id, role_id: this.state.Groups[i].role_id, status: this.state.Groups[i].status,
                    title: this.state.Groups[i].mob_roles.name,
                    checked: this.state.Groups[i].can_read === "0" ? false : true
                },
                {
                    id: "can_create", name: "Create", group_id: this.state.Groups[i].group_id,
                    object_id: this.state.Groups[i].id, role_id: this.state.Groups[i].role_id, status: this.state.Groups[i].status,
                    checked: this.state.Groups[i].can_create === "0" ? false : true
                },
                {
                    id: "can_update", name: "Update", group_id: this.state.Groups[i].group_id,
                    object_id: this.state.Groups[i].id, role_id: this.state.Groups[i].role_id, status: this.state.Groups[i].status,
                    checked: this.state.Groups[i].can_update === "0" ? false : true
                },
                {
                    id: "can_delete", name: "Delete", group_id: this.state.Groups[i].group_id,
                    object_id: this.state.Groups[i].id, role_id: this.state.Groups[i].role_id, status: this.state.Groups[i].status,

                    checked: this.state.Groups[i].can_delete === "0" ? false : true
                }],
            }, function () {

                data.push(Object.assign(this.state.options));
                this.setState({
                    data: data,
                })
            })
        }
        console.log("Group", this.state.data)
    }


    handleCheckboxChange = (checked, option) => {
        const { options } = this.state;
        var cOptions = [...options];
        var dataArray = []
        this.state.data.map(items => {
            items.map(item => {
                if (item.object_id == option.object_id) {
                    if (item.id == option.id) {
                        item.checked = checked;
                        const objs = Object.fromEntries(items.map(bayo => [bayo.id, bayo.checked ? "1" : "0",]));
                        console.log("bayo", objs)
                        let data = []
                    }
                }
            })
            let grop1;
            let grop2;
            let grop3;
            items.map(item => {
                grop1 = { group_id: item.group_id.toString() }
                grop2 = { role_id: item.role_id.toString() }
                grop3 = { status: item.status }
            })
            let data = []
            const obj = Object.fromEntries(items.map(bayo => [bayo.id, bayo.checked ? "1" : "0",]));
            dataArray.push(Object.assign(obj, grop1, grop2, grop3));
            this.setState({
                ...this.state,
                dataArray: dataArray
            }, function () {
                localStorage.setItem("DATAARRAY", JSON.stringify(dataArray))
            })
        })
        console.log("evan", dataArray)
        console.log("BAYO", this.state.dataArray)
    }

    onSubmit() {
        console.log("TEST", JSON.parse(localStorage.getItem("DATAARRAY")))
        let formData = {
            "items": JSON.parse(localStorage.getItem("DATAARRAY"))
        }
        this.setState({ isLoading: true })
        console.log("data", formData)

        // alert(JSON.stringify(formData))
        axios.put(baseURL + "group-roles", formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        }).then(response => {
            console.log("true", response.data)
            // this.setState({
            //     isLoading: false,
            //     showComponent: true,
            //     hideComponent: true
            // })
            this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false }, function () {
                window.setTimeout(() => {
                    this.setState({ isShowError: false })
                }, 8000);
            });
        }, error => {
            this.setState({ statusMessage: "Saved Successfully", isShowError: true, isLoading: false });
            window.setTimeout(() => {
                this.setState({ isShowError: false })
            }, 2000);
        });
    }
    _onButtonClick3() {
        this.setState({
            showComponent: true,
            hideComponent: true
        })
    }

    render() {
        const { options, data, showComponent, hideComponent, Groups } = this.state;
        let menuItems = [];
        let menuItem = [];
        data.map(items => {
            // console.log("bayo", )
            // menuItem.push(<h6 style={{ marginLeft: "30dp" }}></h6>)
            menuItems.push(
                <div>
                    <div className="wrapper text-center">
                        <div className="contain offset-1" >
                            {
                                items.map(option => {
                                    return (
                                        < >
                                            <h4><b>{option.title}</b></h4><br></br>
                                            <CheckboxField key={option.id} bayo={option.id}
                                                name={option.name} checked={option.checked}
                                                onChange={value => this.handleCheckboxChange(value, option)} />
                                        </>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            );
        })
        return (
            <Loader loaded={this.state.loaded}>
                <div>
                    {!hideComponent && (
                        < >
                            < Col md={12} lg={12} >
                                < Card >
                                    <CardBody >
                                        <div className="panel-body col-12" >

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <h4><b>{localStorage.getItem("admin_name")}' Permission</b></h4>
                                                    {this.state.showError ? <div >

                                                        <Alert color="danger" className="alert--colored" icon>
                                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                                            </p>
                                                        </Alert>


                                                    </div> : null}<br></br>

                                                    {this.state.isShowError ? (
                                                        <div>

                                                            <Alert color="success" className="alert--colored" icon>
                                                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                                                </p>
                                                            </Alert>

                                                        </div>

                                                    ) : null}<br></br>
                                                </div>
                                                <div className="col-md-6 " >
                                                    <Link to="groups">
                                                        <Button className="pull-right" color="primary
                                                    " onClick={this._onButtonClick3} outline>Groups
                                                </Button>
                                                    </Link>
                                                </div>
                                            </div>
                                            <br />
                                            <br />
                                            <Row xs="6" >
                                                {menuItems}
                                            </Row><br />
                                            <div className="col-md-12">
                                                <br /><br />
                                                <Button type="submit" color="primary" onClick={this.onSubmit} className="btn-paypal btn-lg pull-right
                                                                 text-white " >
                                                    {this.state.isLoading ? "Please Wait" : "Save"}  <i className="fa fa-refresh"></i></Button> &nbsp;&nbsp;&nbsp;
                                       </div>
                                        </div>
                                    </CardBody>
                                </ Card>
                            </ Col>
                        </>
                    )}
                    {
                        showComponent && (
                            < >
                                <Grps />
                            </>
                        )
                    }

                </div >
            </Loader>

        );



    }
}