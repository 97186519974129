import React from 'react';
import '../../_assets/css/file.css';
import { Form } from "react-bootstrap";
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { TOKEN } from '../../_helpers/token';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import $ from 'jquery'
import jQuery from 'jquery';
import { Link } from 'react-router-dom';
import { Schedulers } from './reschedulers';

window.$ = window.jQuery = require('jquery')

class CreateSchedule extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };
    constructor(props) {
        super(props)
        this.state = {
            showComponent: false,
            hideComponent: true,
        };
        this._onButtonClick = this._onButtonClick.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            event_name: '',
            event_description: '',
            start_time: '',
            end_time: '',
            entity_type: '4',
            isLoading: false,
            isShowError: false,
            statusMessage: '',
            applicable_to: '',
            short_code: '',
            selectedEntity: '',
            hideEntitiy: false,
            hideBranch: false,
            transit: '',
            plans: '',
            event_date: '',
            fee: '',
            showError: false,
            type: [{
                name: 'MT'
            }]
        }
    }

    async componentDidMount() {
        $('#message').countSms('#sms-counter');

        const [
            countyResponse] = await Promise.all([
                axios.get(baseURL + 'shortcodes', { headers: { "Authorization": `Bearer ` + TOKEN } }),
            ]);
        this.setState({
            isLoading: false,
            BreakingNews: countyResponse.data.data,

        })
    }
    handleChangeMessage = (event) => {
        this.setState({ selectedMessage: event.target.value }, function () {

        });
        //alert(event.target.value)
    };


    ShortCodes() {
        return (this.state.BreakingNews && this.state.BreakingNews.length > 0 &&
            this.state.BreakingNews.map((countyItem, i) =>
                ({ label: countyItem.name, value: countyItem.shortcode_id })))

    }

    onSelectChanges = value => {
        this.setState({ shortcode_id: value.value.toString() },
            function () {
                fetch(baseURL + 'services?shortcode_id=' + value.value.toString(),
                    { headers: { "Authorization": `Bearer ` + TOKEN } })
                    .then(response => response.json())
                    .then(
                        res => {
                            this.setState({ services: res.data },
                                console.log("bayd", res)
                            )
                        }
                    )

            });
    };

    services() {
        return (this.state.services && (this.state.services.length > 0 || this.state.services.length === 0) &&
            this.state.services.map((countyItem, i) =>
                ({ label: countyItem.offer_name, value: countyItem.service_id })))

    }

    onSelectService = value => {
        this.setState({ service_id: value.value.toString() })
    }

    handleChangeRegion = (event) => {
        this.setState({ selectedPriority: event.target.value });
    };


    handleChangeType = (event) => {
        this.setState({ selectedType: event.target.value });
    };

    onSubmit(e) {
        e.preventDefault();
        let ttl_new = (this.state.ttl * 1000)

        let formData =
        {
            "service_id": this.state.service_id,
            "message": this.state.selectedMessage,
            "broadcast_type": this.state.selectedType,
            "schedule_date": this.state.schedule_date,
            "schedule_time": this.state.schedule_time,
        }



        console.log("DATA", JSON.stringify(formData))
        this.setState({ isLoading: true });
        axios.post(baseURL + 'schedules', formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        })
            .then((response) => {
                console.log('bayoo', response.data)

                if (response.data.status) {
                    this.setState({ statusMessage: response.data.status_message, showError: false, isShowError: true, isLoading: false });
                    window.setTimeout(function () {
                        window.location.reload();
                    }, 2000);
                } else {
                    this.setState({ statusMessage: response.data.status_message, showError: true, isShowError: false, isLoading: false });
                }
            })
            .catch((error) => {
                console.log('bayoo', error)
                this.setState({ statusMessage: error.response.data.status_message, showError: true, isShowError: false, isLoading: false });
            })
    }


    onSelectChange = value => {
        this.setState({ entity_id: value.value.toString() });
    };

    handleChangeEntityChange = (event) => {
        this.setState({ values: event.target.value });
    };

    handleChangeEntityType = (event) => {
        this.setState({ entity_type: event.target.value });
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChangeCountry = (event) => {
        this.setState({ selectedCountry: event.target.value });
    };

    handleChangeCounty = (event) => {
        this.setState({ selectedCounty: event.target.value });
    };

    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,
        });
    }

    changeStatus() {
        this.setState({
            isShowError: false
        });
    }


    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;
        const { hideEntitiy } = this.state;
        const { hideBranch } = this.state;
        const { t } = this.props;

        return (
            <div>
                {showComponent && (
                    <div>
                        {this.state.showComponent ?
                            <Schedulers /> : null
                        }
                    </div>
                )}

                {!hideComponent && (
                    <>
                        <Col md={12}>
                            <h3>Create Schedulers</h3><br />
                        </Col>
                        <Col md={12} lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h5 className="bold-text">Create Schedulers</h5>
                                        </div>
                                        <div className="col-md-6">
                                            <Link to="schedulers">
                                                <Button className="pull-right" outline color="primary" onClick={this._onButtonClick}>
                                                    Schedulers
                                            </Button>
                                            </Link>

                                        </div>
                                    </div>

                                    {this.state.showError ? <div style={{ color: 'red' }}>
                                        {this.state.statusMessage}
                                    </div> : null}<br></br>
                                    {this.state.isShowError ? (
                                        <div
                                            color="success"
                                            style={{ fontSize: "13px", color: "green" }}>
                                            {this.state.statusMessage}
                                        </div>

                                    ) : null}
                                    <form className="form" onSubmit={this.onSubmit}>
                                        <br /><br />
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Select ShortCodes</label>
                                                <Select
                                                    options={this.ShortCodes()}
                                                    onChange={this.onSelectChanges}
                                                    placeholder="Select ShortCodes"
                                                    tabSelectsValue={false}
                                                    value={this.state.onSelectChanges}
                                                    className='drop'
                                                />
                                                <br />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Select Services</label>
                                                <Select
                                                    options={this.services()}
                                                    onChange={this.onSelectService}
                                                    placeholder="Select Services"
                                                    tabSelectsValue={false}
                                                    value={this.state.onSelectService}
                                                    className='drop'
                                                />
                                                <br />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="Focus-line" >
                                                <Form.Label>Broadcast Type</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    className="form-control input-md"
                                                    placeholder="Select Type"
                                                    value={this.state.selectedType}
                                                    onChange={this.handleChangeType}
                                                    id="county_name"
                                                    option={this.selectedType}>
                                                    <option>Select BroadCast Type</option>
                                                    {
                                                        this.state.type && this.state.type.length > 0 &&
                                                        this.state.type.map((regionItem, i) =>
                                                            <option key={i}
                                                                value={regionItem.name}>{regionItem.name}</option>)
                                                    }
                                                </Form.Control>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Schedule Date</label>
                                                <input id="input" type="date" className="form-control" name="schedule_date"
                                                    required placeholder="Schedule Date"
                                                    value={this.state.schedule_date}
                                                    onChange={this.handleChange} />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Schedule Time</label>
                                                <input id="input" type="time" className="form-control"
                                                    name="schedule_time" required placeholder="Schedule Time" step="1"
                                                    value={this.state.schedule_time} onChange={this.handleChange} />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <br />
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">

                                                        <label className="form-label">Message</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <textarea name="message" required style={{ paddingTop: '20px' }}
                                                            onChange={this.handleChangeMessage} className="col-md-12"
                                                            placeholder="Hint: Enter Message here"
                                                            id="message" cols="col-md-1" rows="20"></textarea>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <ul id="sms-counter">
                                                            <li>Length: <span class="length"></span></li>
                                                            <li>Per Message: <span class="per_message"></span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-md-12">
                                            <br /><br />
                                            <Button type="submit" color="primary" className="btn-paypal btn-lg pull-right
                                                                 text-white " >
                                                {this.state.isLoading ? "Please Wait" : "Submit"}  <i className="fa fa-refresh"></i></Button> &nbsp;&nbsp;&nbsp;
                                       </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </>
                )}
            </div>
        )
    }
}
export default withTranslation('common')(CreateSchedule);
