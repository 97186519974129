import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Visits from './components/Visits';
import TotalPageViews from './components/TotalPageViews';
import NewUsers from './components/NewUsers';
import BounceRate from './components/BounceRate';
import { RTLProps } from '../../../shared/prop-types/ReducerProps';
import NFCTags from './components/NFCTags';
import { ROLE } from '../../../_helpers/token';
import Survey from './components/Breaking';
import Questions from './components/Qustions';
import TotalCount from './components/TotalCount';
import BreakingAdminnews from './components/breakingNews';
import Subscribers from './components/subscribers';
import OutBounds from './components/outbound';
import MessageTermination from '../messsagetermination';


function DefaultDash({ t, rtl }) {
  // const [account1, setAccount1] = React.useState(0);


  useEffect(() => {
    //alert("Welcome to this");
    // let axiosConfig = {
    //   headers: {
    //     'Content-Type': 'application/json;charset=UTF-8',
    //     "Access-Control-Allow-Origin": "*",
    //     "Authorization": "Bearer " + TOKEN
    //   }
    // };
    // let url = baseURL + "dashboard"
    // console.log("query", url);

    // axios.all([
    //   axios.get(url, axiosConfig),
    // ]).then(axios.spread((branchResponse) => {
    //   console.log("BUG", branchResponse.data.data)
    //   localStorage.setItem("group", branchResponse.data.data.groups[0].count)
    //   localStorage.setItem("outbound_emails", branchResponse.data.data.outbound_emails[0].count)
    //   localStorage.setItem("roles", branchResponse.data.data.roles[0].count)
    //   localStorage.setItem("shortcodes", branchResponse.data.data.shortcodes[0].count)
    //   localStorage.setItem("subs", branchResponse.data.data.subs[0].count)
    //   localStorage.setItem("users", branchResponse.data.data.users[0].count)
    //   localStorage.setItem("breaking", branchResponse.data.data.breakingNews[0].count)
    //   localStorage.setItem("accoutn", branchResponse.data.data.accounts[0].count)
    //   localStorage.setItem("surveyssss", branchResponse.data.data.surveys[0].count)
    //   localStorage.setItem("quest", branchResponse.data.data.questions[0].count)
    // })).catch(error => {
    //   console.log('bayoo', error.response)

    // })
    // // setAccount1(10);
  })

  return (
    <Col className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Dashboard</h3>
        </Col>
      </Row>
      <Row >
        {ROLE === '1' ?
          <>
            <BreakingAdminnews />
            <Visits />
            <NewUsers />
            <Subscribers />
            
            {/* <MessageTermination/>

             */}
            {/* <OutBounds />
            <BounceRate /> */}
          </>
          : null}

        {/* {ROLE === '5' ?
          <>
            <BounceRate />
            <Survey />
            <Questions />
          </>
          : null} */}

        {ROLE === '5' ?
          <>
            <TotalCount />
            <BounceRate />
            <Survey />
            <Questions />


          </>
          : null}


        {ROLE === '3' ?
          <>
            <TotalCount />

            <BounceRate />
            <Survey />
            <Questions />


          </>
          : null}

      </Row>
      {/* <Row>
        <ABTestingAnalytics dir={rtl.direction} />

        <BestSellingRegions />
        <GoalsCompletion />
      </Row> */}
    </Col>
  )
}

DefaultDash.propTypes = {
  t: PropTypes.func.isRequired,
  rtl: RTLProps.isRequired,
};

export default compose(withTranslation('common'), connect(state => ({
  rtl: state.rtl,
})))(DefaultDash);
