import React from 'react';
import '../../_assets/css/file.css';
import { Form } from "react-bootstrap";
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { TOKEN } from '../../_helpers/token';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Questions } from './questions';
import DatePicker from "react-datepicker";
import TimePicker from 'react-time-picker/dist/entry.nostyle'
import * as moment from 'moment';
import { Link } from 'react-router-dom';
import $ from 'jquery'
import jQuery from 'jquery';
import Alert from '../../shared/components/Alert';
window.$ = window.jQuery = require('jquery')

class QuestionCreate extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };
    constructor(props) {
        super(props)
        this.state = {
            showComponent: false,
            hideComponent: true,
        };
        this._onButtonClick = this._onButtonClick.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            event_name: '',
            event_description: '',
            entity_type: '4',
            start_time: '10:00:00',
            isLoading: false,
            isShowError: false,
            statusMessage: '',
            applicable_to: '',
            start_date: new Date(),
            end_date: new Date(),

            short_code: '',
            selectedEntity: '',
            hideEntitiy: false,
            hideBranch: false,
            transit: '',
            plans: '',
            event_date: '',
            fee: '',
            showError: false,
            type: [{
                name: 'Rating'
            }, {
                name: 'Closed-ended'
            }],

            priority: [{
                name: '1'
            }, {
                name: '2'
            },
            {
                name: '3'
            },
            {
                name: '4'
            },
            {
                name: '5'
            },
            {
                name: '6'
            },
            {
                name: '7'
            },
            {
                name: '8'
            },
            {
                name: '9'
            },
            {
                name: '10'
            }],
            avenue: '',
            assigned_to: '',
            selectedCounty: 'Select County/State Name',
        }
    }

    handleChangeRegion = (event) => {
        this.setState({ selectedPriority: event.target.value });
    };


    handleChangeType = (event) => {
        this.setState({ selectedType: event.target.value });
    };

    onSubmit(e) {
        e.preventDefault();
        let ttl_new = (this.state.ttl * 60)
        let Sdate = moment.utc(this.state.start_date).format("yyyy-MM-DD");
        let Edate = moment.utc(this.state.end_date).format("yyyy-MM-DD");
        let Stime = moment(this.state.start_date).format("HH:mm:ss");
        let Etime = moment(this.state.end_date).format("HH:mm:ss");

        let formData = {
            // "priority": this.state.selectedPriority,
            "survey_id": localStorage.getItem("survey_id"),
            // "start_date": Sdate,
            // "start_time": Stime,
            // "end_time": Etime,
            // "end_date": Edate,
            "ttl": ttl_new,
            "type": this.state.selectedType,
            "question": this.state.selectedMessage,

        }
        console.log("DATA", JSON.stringify(formData))
        this.setState({ isLoading: true });
        axios.post(baseURL + 'question', formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        })
            .then((response) => {
                console.log('bayoo', response.data)

                if (response.data.status) {
                    this.setState({ statusMessage: response.data.status_message, showError: false, isShowError: true, isLoading: false });
                    window.setTimeout(() => {
                        this.setState({ isShowError: false });
                        this._onButtonClick()
                    }, 2000);
                } else {
                    this.setState({ statusMessage: response.data.status_message, showError: true, isShowError: false, isLoading: false });
                }
            })
            .catch((error) => {
                console.log('bayoo', error)
                this.setState({ statusMessage: error.response.data.status_message, showError: true, isShowError: false, isLoading: false });
            })
    }


    onSelectChange = value => {
        this.setState({ entity_id: value.value.toString() });
    };

    handleChangeEntityChange = (event) => {
        this.setState({ values: event.target.value });
    };

    handleChangeEntityType = (event) => {
        this.setState({ entity_type: event.target.value });
    };
    handleChangeApplicable = (event) => {
        this.setState({
            applicable_to: event.target.value,
        });
        if (event.target.value === 'entity') {

            this.setState({
                hideEntitiy: true,
                hideBranch: false,
            });
        } else if (event.target.value === 'branch') {
            this.setState({
                hideBranch: true,
                hideEntitiy: false,
            });

        }
        else if (event.target.value === 'class') {
            this.setState({
                hideBranch: false,
                hideEntitiy: false,
            });

        }
        else if (event.target.value === 'stream') {
            this.setState({
                hideBranch: false,
                hideEntitiy: false,
            });

        }
    };
    handleChangeMessage = (event) => {
        this.setState({ selectedMessage: event.target.value }, function () {

        });
        //alert(event.target.value)
    };

    handleChangeTransit = (event) => {
        this.setState({ transit: event.target.value });
    };
    handleChangeFee = (event) => {
        this.setState({ fee: event.target.value });
    };
    handleChangePlans = (event) => {
        this.setState({ plans: event.target.value });
    };
    handleChangeScope = (event) => {
        this.setState({ event_scope: event.target.value });
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChangeCountry = (event) => {
        this.setState({ selectedCountry: event.target.value });
    };

    handleChangeCounty = (event) => {
        this.setState({ selectedCounty: event.target.value });
    };

    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,
        });
    }

    changeStatus() {
        this.setState({
            isShowError: false
        });
    }
    componentDidMount() {
        $('#message').countSms('#sms-counter');
    }


    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;
        const { hideEntitiy } = this.state;
        const { hideBranch } = this.state;
        const { t } = this.props;

        return (
            <div>
                {showComponent && (
                    <div>
                        {this.state.showComponent ?
                            <Questions /> : null
                        }
                    </div>
                )}

                {!hideComponent && (
                    <>
                        <Col md={12} lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h4><b>Create Question</b></h4><br />
                                        </div>
                                        <div className="col-md-6">
                                            <Link to="questions">
                                                <Button className="pull-right" outline color="primary" onClick={this._onButtonClick}>
                                                    Questions
                                            </Button>
                                            </Link>

                                        </div>
                                    </div>


                                    {this.state.showError ? <div >

                                        <Alert color="danger" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>


                                    </div> : null}<br></br>

                                    {this.state.isShowError ? (
                                        <div>

                                            <Alert color="success" className="alert--colored" icon>
                                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                                </p>
                                            </Alert>

                                        </div>

                                    ) : null}<br></br>
                                    <form className="form" onSubmit={this.onSubmit}>
                                        {/* <div className="col-md-8 offset-2">
                                            <Form.Group className="Focus-line" >
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <Form.Label>Priority</Form.Label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <Form.Control
                                                            as="select"
                                                            className="form-control input-md"
                                                            placeholder="Select Priority"
                                                            value={this.state.selectedPriority}
                                                            onChange={this.handleChangeRegion}
                                                            id="county_name"
                                                            option={this.selectedPriority}>
                                                            <option>Select Priority</option>
                                                            {
                                                                this.state.priority && this.state.priority.length > 0 &&
                                                                this.state.priority.map((regionItem, i) =>
                                                                    <option key={i}
                                                                        value={regionItem.name}>{regionItem.name}</option>)
                                                            }
                                                        </Form.Control>
                                                    </div>
                                                </div>
                                            </Form.Group>
                                        </div> */}
                                        <div className="col-md-8 offset-2">
                                            <Form.Group className="Focus-line" >
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <Form.Label>Type</Form.Label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <Form.Control
                                                            as="select"
                                                            className="form-control input-md"
                                                            placeholder="Select Type"
                                                            value={this.state.selectedType}
                                                            onChange={this.handleChangeType}
                                                            id="county_name"
                                                            option={this.selectedType}>
                                                            <option>Select Type</option>
                                                            {
                                                                this.state.type && this.state.type.length > 0 &&
                                                                this.state.type.map((regionItem, i) =>
                                                                    <option key={i}
                                                                        value={regionItem.name}>{regionItem.name}</option>)
                                                            }
                                                        </Form.Control>
                                                    </div>
                                                </div>

                                            </Form.Group>
                                        </div>
                                        <div className="col-md-8 offset-2"><br />
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label className="form-label">Time to Live (Minutes)</label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <input id="input" type="number" className="form-control"
                                                            name="ttl" required placeholder='Enter Time to Live in Minutes'
                                                            value={this.state.ttl} onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-8 offset-2">

                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">

                                                        <label className="form-label">Question</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <textarea name="message" maxLength="160" required style={{
                                                            paddingTop: '20px',
                                                            borderRadius: "5px", borderColor: "hsl(0,0%,80%)"
                                                        }}
                                                            onChange={this.handleChangeMessage} className="col-md-12"
                                                            placeholder="Hint: Enter question here"
                                                            id="message" cols="col-md-1" rows="20"></textarea>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <ul id="sms-counter">
                                                            <li>Length: <span class="length"></span></li>
                                                            {/* <li>SMS: <span class="messages"></span></li> */}
                                                            <li>Per Question: <span class="remaining"></span>/<span class="per_message"></span></li>
                                                            {/* <li>Remaining: </li> */}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                        </div>
                                        {/* <div className="col-md-8 offset-2">

                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label className="form-label">Question</label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <input id="input" type="text" className="form-control input-md"
                                                            name="question" required placeholder="Enter Qustion"
                                                            value={this.state.question} onChange={this.handleChange} />
                                                    </div></div>
                                            </div>
                                        </div> */}
                                        {/* <div className="col-md-8 offset-2">
                                            <Form.Group className="Focus-line" >
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <Form.Label>Start date</Form.Label>
                                                    </div>
                                                    <div className="col-md-10 customDatePickerWidth">
                                                        <DatePicker
                                                            selected={this.state.start_date}
                                                            label="DatePicker Label"
                                                            dateFormat="yyyy-MM-dd HH:mm:ss"
                                                            className="form-control"
                                                            showTimeSelect
                                                            timeIntervals={1}
                                                            id="input"
                                                            onChange={value => this.setState({ start_date: value })} />


                                                    </div>
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-8 offset-2">
                                            <Form.Group className="Focus-line" >
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <Form.Label>End date</Form.Label>
                                                    </div>
                                                    <div className="col-md-10 customDatePickerWidth">
                                                        <DatePicker
                                                            selected={this.state.end_date}
                                                            label="DatePicker Label"
                                                            dateFormat="yyyy-MM-dd HH:mm:ss"
                                                            showTimeSelect
                                                            timeIntervals={1}
                                                            style={{ color: "black" }}
                                                            id="input"
                                                            onChange={value => this.setState({ end_date: value })} />
                                                    </div>

                                                </div>
                                            </Form.Group>
                                        </div> */}

                                        <br></br>
                                        <br />
                                        <br></br>
                                        <br />

                                        <div className="col-md-8 offset-2 form-group">
                                            <br />
                                            <Button type="submit" color="primary" outline className="btn-paypal btn-lg pull-right" >
                                                {this.state.isLoading ? "Please Wait..." : "Submit"}

                                            </Button> &nbsp;&nbsp;&nbsp;
                                        </div>

                                    </form>
                                </CardBody>
                            </Card>
                        </Col >
                    </>
                )
                }
            </div>
        )
    }
}
export default withTranslation('common')(QuestionCreate);
