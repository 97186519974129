import React, { Fragment } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import { Button, Card, CardBody, Col } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { Form } from "react-bootstrap";
import { TOKEN } from '../../_helpers/token';
import * as moment from 'moment';
import { Survey } from '../Survey/survey';
import { Questions } from '../Qauestions/questions';
import { Link } from 'react-router-dom';
import CreateAnswers from './createAnswers';
import { confirm } from "../../shared/components/confirm";
import Alert from '../../shared/components/Alert';

export class Answers extends React.Component {
    constructor(props) {
        super(props)
        this._onButtonClick = this._onButtonClick.bind(this);
        this._onButtonClick2 = this._onButtonClick2.bind(this);
        this.onSubmitNewUpdates = this.onSubmitNewUpdates.bind(this);
        this._onButtonClick3 = this._onButtonClick3.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);

        this.columns = [
            // {
            //     key: "idx",
            //     TrOnlyClassName: 'tsc',
            //     text: "#",
            //     className: "tsc",
            //     align: "left"
            // },
            {
                key: "acceptable_answers",
                TrOnlyClassName: 'tsc',
                text: "Acceptable Answer",
                className: "tsc",
                align: "left"
            },
            {
                key: "answer",
                TrOnlyClassName: 'tsc',
                text: "Answer",
                className: "tsc",
                align: "left"
            },

            {
                key: "feedback",
                TrOnlyClassName: 'tsc',
                text: "Feedbacks",
                className: "tsc",
                align: "left"
            },


            {
                key: "dates",
                TrOnlyClassName: 'tsc',
                text: "Date Created",
                className: "tsc",
                align: "left"
            },
            {
                key: "action",
                text: "Options",
                TrOnlyClassName: 'cell',
                className: "cell",
                width: 250,
                sortable: false,
                cell: record => {
                    return (
                        <Fragment className="center" >
                            <button className="btn btn-primary btn-sm"
                                title="Eith details"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this._onShow(record) }} >
                                Edit
                            </button>
                            <button className="btn btn-danger btn-sm"
                                title="Delete details"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.handleOnClick(record) }} >
                                Remove
                            </button>
                            {/* <button className=" btn-success"
                                title="Questions details"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.onQuestions(record) }} >
                                <span className="fa fa-trash dt-icon-btn" > Answers </span>
                            </button> */}


                        </Fragment>
                    );
                }
            }
        ];
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            page_size: 10,
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Search...",
                no_data_text: "No data was found",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }

        }
        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            editShow: false,
            showError: false,
            isShowError: false,
            showComponent: false,
            hideComponent: false,
            data: [],
            priority: [{
                name: '1'
            }, {
                name: '2'
            },
            {
                name: '3'
            },
            {
                name: '4'
            },
            {
                name: '5'
            },
            {
                name: '6'
            },
            {
                name: '7'
            },
            {
                name: '8'
            },
            {
                name: '9'
            },
            {
                name: '10'
            }],
        };

    }

    async handleOnClick(record) {
        console.log("hoge!");
        if (await confirm("Are your sure?")) {
            this.onDelete(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }


    componentDidMount() {
        this.getData();
    }

    getData = (queryString = "") => {
        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + TOKEN
            }
        };
        let url = baseURL + "answer?question_id=" + localStorage.getItem("question_id") + "&" + queryString
        console.log("query", url);

        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((branchResponse) => {
            this.setState({
                admins: branchResponse.data.data,
                isLoading: false,
            },
                function () {
                    var data = [];
                    console.log("BUG", this.state.admins)

                    if (this.state.admins.length > 0 || this.state.admins.length === 0) {

                        for (let i = 0; i < this.state.admins.length; i++) {
                            this.setState({
                                total: branchResponse.data.total
                            })
                            let mail;
                            let mbalance;
                            let phone;
                            let index = { idx: i + 1 }
                            if (this.state.admins[i].email == null) {
                                mail = { mail: "_" };
                            } else {
                                mail = { mail: this.state.admins[i].email };
                            }

                            if (this.state.admins[i].balance === 0) {
                                mbalance = { mbalance: "0.0" };
                            } else {
                                mbalance = { mbalance: this.state.admins[i].balance };
                            }

                            if (this.state.admins[i].msisdn === null) {
                                phone = { phone: "_" };
                            } else {
                                phone = { phone: this.state.admins[i].msisdn };
                            }

                            let date = { dates: moment.utc(this.state.admins[i].created_at).format('YYYY-dd-MM HH:mm:ss') };
                            data.push(Object.assign(date, mail, phone, index, mbalance, this.state.admins[i]));
                            this.setState({
                                data: data
                            })
                            console.log("bugs", data);

                        }
                    }

                })
        })).catch(error => {
            console.log('bayoo', error.response)

        })
    }



    onDelete(record) {

        console.log("Delete Record", record);
        axios.delete(baseURL + "answer/" + record.id, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        }).then(response => {
            console.log("true", response.data)
            if (response.data.status) {
                this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData();
                        this.setState({ isShowError: false })
                    }, 2000);
                });
            } else {
                this.setState({ statusMessage: response.data.status_message, isShowError: false, showError: true, isLoading: false });
                window.setTimeout(() => {
                    this.getData();
                    this.setState({ isShowError: false })
                }, 2000);
            }
        }, error => {
            this.setState({ statusMessage: JSON.stringify(error), showError: true, isShowError: false, isLoading: false });
            window.setTimeout(() => {
                this.getData();
                this.setState({ isShowError: false })
            }, 2000);
        });
    }
    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }


    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,
            editShow: false,
            showModal: false,
        }, function(){
            // localStorage.removeItem("question_id");
        });
    }

    _onButtonClick2() {
        this.setState({
            showComponent: false,
            hideComponent: true,
            editShow: false,
            showModal: true,
        });
    }
    handleChangeRegion = (event) => {
        this.setState({ selectedPriority: event.target.value });
    };


    _onButtonClick3() {
        this.setState({
            showComponent: false,
            hideComponent: false,
            showModal: false,
            _onShow: false,
            showEdit: false,
            editShow: false,
            showModals: false
        });
    }


    _onShow(record) {
        this.setState({
            showComponent: false,
            hideComponent: true,
            editShow: true,
            acceptable_answers: record.acceptable_answers,
            answer: record.answer,
            answer_id: record.id,
            showModal: false,
        });
    }

    onDelete(record) {
        let formData = {
            "mobile": record.mobile
        }
        console.log("Delete Record", record);
        axios.delete(baseURL + "answer/" + record.id, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        }).then(response => {
            console.log("true", response.data)
            if (response.data.status) {
                console.log("records", formData)
                this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData();
                    }, 2000);

                });
            } else {
                this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false });
                window.setTimeout(() => {
                    this.getData();
                    this.setState({ isShowError: false })
                }, 2000);
            }
        }, error => {
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
        });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    onSubmitNewUpdates(e) {
        e.preventDefault();
        let ttl_new = (this.state.ttl * 1000)
        let formData = {
            // "priority": this.state.selectedPriority,
            "question_id": localStorage.getItem("question_id"),
            "acceptable_answers": this.state.acceptable_answers,
            "answer": this.state.answer
        }
        console.log("DATA", JSON.stringify(formData))
        this.setState({ isLoading: true });
        axios.put(baseURL + 'answer/' + this.state.answer_id, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        }).then((response) => {
            console.log('bayoo', response.data)
            if (response.data.status) {

                this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this._onButtonClick3();
                        this.setState({ isShowError: false })
                    }, 2000);
                });
                // window.setTimeout(function () {

            } else {
                this.setState({ statusMessage: response.data.status_message, isShowError: false, showError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this._onButtonClick3();
                        this.setState({ isShowError: false })
                    }, 2000);
                });

            }
        })
            .catch((error) => {
                console.log('bayoo', error)
                this.setState({ statusMessage: error.response.data.status_message, showError: true, isShowError: false, isLoading: false });
            })
    }



    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;
        const { showModal, editShow } = this.state;
        return (
            <div >
                {!hideComponent && (
                    < >
                        < Col md={12} lg={12} >
                            < Card >
                                <CardBody >
                                    <div className="panel-body" >
                                        <h4><b>List of Answers</b></h4><br />

                                        <div className="row">
                                            <div className="col-md-6">
                                                <Button color="primary" onClick={this._onButtonClick} outline>Questions
                                            </Button>
                                            </div>
                                            <div className="col-md-6">

                                                {/* {localStorage.getItem("question_id") === "Closed-ended" ? */}
                                                <Link to="/answerCreate">
                                                    <Button className="pull-right" color="primary
                                                    " onClick={this._onButtonClick2} outline>Create Answers
                                                </Button>
                                                </Link>
                                                {/* : null} */}
                                            </div>
                                        </div>

                                        {this.state.showError ? <div >

                                            <Alert color="danger" className="alert--colored" icon>
                                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                                </p>
                                            </Alert>


                                        </div> : null}<br></br>

                                        {this.state.isShowError ? (
                                            <div>

                                                <Alert color="success" className="alert--colored" icon>
                                                    <p><span className="bold-text">{this.state.statusMessage}</span>
                                                    </p>
                                                </Alert>

                                            </div>

                                        ) : null}<br></br>
                                        <ReactDatatable
                                            config={this.config}
                                            records={this.state.data}
                                            columns={this.columns}
                                            dynamic={true}
                                            id="tsc"
                                            loading={this.state.isLoading}
                                            total_record={this.state.total}
                                            onChange={this.tableChangeHandler} />
                                    </div>
                                </CardBody>

                            </Card>
                        </Col>
                    </>
                )}
                {showComponent && (
                    < >
                        <Questions />
                    </>
                )}
                {showModal && (
                    < >
                        <CreateAnswers />
                    </>
                )}

                {editShow && (
                    <>
                        <Col md={12} lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h4><b>Edit a Answer</b></h4>
                                        </div>
                                        <div className="col-md-6">
                                            <Button className="pull-right" outline color="primary" onClick={this._onButtonClick3}>
                                                Answers
                                            </Button>

                                        </div>
                                    </div>


                                    {this.state.showError ? <div >

                                        <Alert color="danger" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>


                                    </div> : null}<br></br>

                                    {this.state.isShowError ? (
                                        <div>

                                            <Alert color="success" className="alert--colored" icon>
                                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                                </p>
                                            </Alert>

                                        </div>

                                    ) : null}<br></br>
                                    <form className="form" onSubmit={this.onSubmitNewUpdates}>


                                        {/* <div className="col-md-8 offset-2">

                                            <Form.Group className="Focus-line" >
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <Form.Label>Priority</Form.Label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <Form.Control
                                                            as="select"
                                                            className="form-control input-md"
                                                            placeholder="Select Priority"
                                                            value={this.state.selectedPriority}
                                                            onChange={this.handleChangeRegion}
                                                            id="county_name"
                                                            option={this.selectedPriority}>
                                                            <option>Select Priority</option>
                                                            {
                                                                this.state.priority && this.state.priority.length > 0 &&
                                                                this.state.priority.map((regionItem, i) =>
                                                                    <option key={i}
                                                                        value={regionItem.name}>{regionItem.name}</option>)
                                                            }
                                                        </Form.Control>
                                                    </div>
                                                </div>

                                            </Form.Group>
                                        </div> */}

                                        <div className="col-md-8 offset-2">

                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label className="form-label">Answer</label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <input id="input" type="text" className="form-control input-md"
                                                            name="answer" required placeholder="Enter Answer"
                                                            value={this.state.answer} onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8 offset-2">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label className="form-label">Acceptable Answer</label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <input id="input" type="text" className="form-control" name="acceptable_answers"
                                                            required placeholder="Acceptable Answer"
                                                            value={this.state.acceptable_answers}
                                                            onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8 offset-2 form-group">
                                            <br />
                                            <Button type="submit" color="primary" outline className="btn-paypal btn-lg pull-right" >
                                                {this.state.isLoading ? "Please Wait..." : "Submit"}

                                            </Button> &nbsp;&nbsp;&nbsp;
                                        </div>

                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </>
                )}

            </div>
        )
    }
}