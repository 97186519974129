import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import { ROLE, TOKEN } from '../../../_helpers/token';

class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };
  componentDidMount() {
    // axios.get(baseURL).then(res => { })

    if (TOKEN == null) {
      window.location.href = "/log_in";

    }
  }
  logout = () => {
    window.location.href = "/log_in";

    localStorage.removeItem('token');
  }
  render() {
    const { changeToLight, changeToDark } = this.props;

    return (
      <div className="sidebar__content">

        <ul className="sidebar__block">
          <SidebarLink
            title="Dashboard"
            icon="diamond"
            route="/dashboard"
            onClick={this.hideSidebar}
          />
          {ROLE === '1' ?
            <>
              <SidebarLink
                title="Survey"
                route="/survey"
                icon="briefcase"
                onClick={this.hideSidebar}
              />
              <SidebarLink
                title="Breaking News"
                icon="bullhorn"
                route="/breakingNews"
                onClick={this.hideSidebar}
              />
              <SidebarLink
                title="ShortCodes"
                icon="list"
                route="/shortcode"
                onClick={this.hideSidebar}
              />

              <SidebarLink
                title="Subscribers"
                icon="users"
                route="/subscribers"
                onClick={this.hideSidebar}
              />

              <SidebarLink
                title="Schedulers"
                icon="clock"
                route="/schedulers"
                onClick={this.hideSidebar}
              />
              <SidebarLink
                title="Messages"
                icon="phone"
                route="/incoming"
                onClick={this.hideSidebar}
              />
              {/* <SidebarCategory title="Contacts" icon="users">
                <SidebarLink title="Contacts" route="/contacts" onClick={this.hideSidebar} />
                <SidebarLink title="Create Contacts" route="/contactsCreate" onClick={this.hideSidebar} />
              </SidebarCategory> */}
              <SidebarCategory title="Users" icon="users">
                <SidebarLink title="Users" route="/users" onClick={this.hideSidebar} />
                <SidebarLink title="Permissions" route="/groups" onClick={this.hideSidebar} />
              </SidebarCategory>

            </> : null}

          {ROLE === '2' ?
            <>
              <SidebarLink
                title="Survey"
                route="/survey"
                icon="briefcase"
                onClick={this.hideSidebar}
              />
              <SidebarLink
                title="Breaking News"
                icon="bullhorn"
                route="/breakingNews"
                onClick={this.hideSidebar}
              />

            </> : null}

          {ROLE === '3' ?
            <>
              {/* <SidebarLink
                title="Survey"
                route="/survey"
                icon="briefcase"
                onClick={this.hideSidebar}
              /> */}
              <SidebarLink
                title="Messages"
                icon="phone"
                route="/incoming"
                onClick={this.hideSidebar}
              />

            </> : null}
          {ROLE === '4' ?
            <>
              <SidebarLink
                title="Breaking News"
                icon="bullhorn"
                route="/breakingNews"
                onClick={this.hideSidebar}
              />
            </> : null}
        </ul>

        {/* <ul className="sidebar__block">
          <SidebarCategory title="Layout" icon="layers">
            <button className="sidebar__link" type="button" onClick={changeToLight}>
              <p className="sidebar__link-title">Light Theme</p>
            </button>
            <button className="sidebar__link" type="button" onClick={changeToDark}>
              <p className="sidebar__link-title">Dark Theme</p>
            </button>
          </SidebarCategory>
        </ul> */}
        <ul className="sidebar__block">
          <SidebarLink
            title="Logout"
            route="/log_in"
            onClick={this.logout}
            icon="exit"
          />
{/* 
          <SidebarCategory title="UI Elements" icon="diamond">
            <SidebarLink title="Alerts" route="/ui/alerts" onClick={this.hideSidebar} />
            <SidebarLink title="Buttons" route="/ui/buttons" onClick={this.hideSidebar} />
            <SidebarLink title="Carousel" route="/ui/carousel" onClick={this.hideSidebar} />
            <SidebarLink title="Collapse" route="/ui/collapse" onClick={this.hideSidebar} />
            <SidebarLink title="Grids" route="/ui/grids" onClick={this.hideSidebar} />
            <SidebarLink title="Modals" route="/ui/modals" onClick={this.hideSidebar} />
            <SidebarLink title="Notifications" route="/ui/notifications" onClick={this.hideSidebar} />
            <SidebarLink title="Panels" route="/ui/panels" onClick={this.hideSidebar} />
            <SidebarLink title="Progress Bars" route="/ui/progress_bars" onClick={this.hideSidebar} />
            <SidebarLink title="Range Sliders" route="/ui/range_sliders" onClick={this.hideSidebar} />
            <SidebarLink title="Tabs" route="/ui/tabs" onClick={this.hideSidebar} />
            <SidebarLink title="Timeline" route="/ui/timeline" onClick={this.hideSidebar} />
            <SidebarLink title="Tooltips & Popovers" route="/ui/tooltips" onClick={this.hideSidebar} />
            <SidebarLink title="Typography" route="/ui/typography" onClick={this.hideSidebar} />
          </SidebarCategory> */}
        </ul>



      </div>
    );
  }
}

export default SidebarContent;
