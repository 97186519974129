import React from 'react';
import { Route } from 'react-router-dom';
import Layout from '../../../Layout/index';
import Commerce from './Commerce';
import Crypto from './Crypto';
import Documentation from './Documentation';
import DefaultPages from './DefaultPages';
import Account from './Account';
import ECommerce from './ECommerce';
import Maps from './Maps';
import Charts from './Charts';
import Tables from './Tables';
import Forms from './Forms';
import UI from './UI';
import Chat from '../../../Chat/index';
import Todo from '../../../Todo/index';
import FitnessDashboard from '../../../Dashboards/Fitness/index';
import DefaultDash from '../../../Dashboards/Default/index';
import MobileAppDashboard from '../../../Dashboards/MobileApp/index';
import BookingDashboard from '../../../Dashboards/Booking/index';
import Mail from '../../../Mail/index';
import Target from './Target';
import Blasts from './Blasts';
import BlackList from './BlackList';
import Bets from './Bets';
import Reports from './Reports';
import Finance from './Finance';
import users from './users';
import { FAQs } from '../../../FAQs/faq';
import { CustomersWithdrwals } from '../../../Finance/customerwithdraws';
import { Transaction } from '../../../Finance/transaction';
import { DepositRequests } from '../../../Finance/depositrequests';
import { Survey } from '../../../Survey/survey';
import { Questions } from '../../../Qauestions/questions';
import { Answers } from '../../../Answers/answers';
import { createSurvey } from '../../../Survey/createSurvey';
import { editSurvey } from '../../../Survey/editSurvey';
import QuestionCreate from '../../../Qauestions/createQuestion';
import CreateAnswers from '../../../Answers/createAnswers';
import { BreakingNews } from '../../../BroadCast/broadcast';
import EditBreakingNews from '../../../BroadCast/editbroad';

import { ShortCodes } from '../../../ShortCode/shortcode';
import { Services } from '../../../Services/services';
import CreateService from '../../../Services/createCreate';

import createShortCode from '../../../ShortCode/CreateShortCode';
import CreateShortCode from '../../../ShortCode/CreateShortCode';
import createBN from '../../../BroadCast/createBN';
import { Subscribers } from '../../../Subscribers/subscribers';
import { SubscriberHistory } from '../../../Subscribers/subcriberHistory';
import { Schedulers } from '../../../Schedules/reschedulers';
import createSchedule from '../../../Schedules/createSchedule';
import { Contacts } from '../../../Contacts/contacts';
import { CreateContacts } from '../../../Contacts/createContacts';
import FileUpload from '../../../Form/FileUpload';
import FileUploadDefault from '../../../Form/FileUpload/components/FileUploadDefault';
import { Roles } from '../../../Groups/roles';
import { Grps } from '../../../Groups/Grps';
import  AddUser  from '../../../Groups/addUser';

import { Users } from '../../../users/Users';
import { IncomingMessages } from '../../../IncomingMsgs/incoming';
// import roles from '../../../Groups/roles';

export default () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/dashboard" component={DefaultDash} />
      <Route path="/dashboard_e_commerce" component={Commerce} />
      <Route path="/dashboard_fitness" component={FitnessDashboard} />
      <Route path="/dashboard_crypto" component={Crypto} />
      <Route exact path="/dashboard_mobile_app" component={MobileAppDashboard} />
      <Route path="/dashboard_booking" component={BookingDashboard} />
      <Route path="/ui" component={UI} />
      <Route path="/target" component={Target} />
      <Route path="/termscon" component={FAQs} />
      <Route path="/mail" component={Mail} />
      <Route path="/chat" component={Chat} />
      <Route path="/todo" component={Todo} />
      <Route path="/forms" component={Forms} />
      <Route path="/tables" component={Tables} />
      <Route path="/charts" component={Charts} />
      <Route path="/maps" component={Maps} />
      <Route path="/account" component={Account} />
      <Route path="/survey" component={Survey} />
      <Route path="/questions" component={Questions} />
      <Route path="/answers" component={Answers} />
      <Route path="/createSurvey" component={createSurvey} />
      <Route path="/editSurvey" component={editSurvey} />
      <Route path="/questionCreate" component={QuestionCreate} />
      <Route path="/answerCreate" component={CreateAnswers} />
      <Route path="/breakingNews" component={BreakingNews} />
      <Route path="/shortcode" component={ShortCodes} />
      <Route path="/services" component={Services} />
      <Route path="/createService" component={CreateService} />

      <Route path="/editbreak" component={EditBreakingNews} />

      


      <Route path="/createBreakingNews" component={createBN} />
      <Route path="/subscribers" component={Subscribers} />
      <Route path="/subscribers-history" component={SubscriberHistory} />
      <Route path="/schedulers" component={Schedulers} />
      <Route path="/createSchedule" component={createSchedule} />
      <Route path="/contacts" component={Contacts} />
      <Route path="/incoming" component={IncomingMessages} />

      <Route path="/users" component={Users} />

      <Route path="/contactsCreate" component={CreateContacts} />
      <Route path="/groups" component={Grps} />
       <Route path="/roles" component={Roles} /> 
       <Route path="/addusers" component={AddUser} /> 

      <Route path="/createshortcodes" component={CreateShortCode} />
      <Route path="/contacts-csv" component={FileUploadDefault} />

      <Route path="/blasts" component={Blasts} />
      <Route path="/" component={BlackList} />
      <Route path="/" component={Bets} />
      <Route path="/" component={Reports} />
      <Route path="/" component={Finance} />
      <Route path="/" component={users} />
      <Route path="/e-commerce" component={ECommerce} />
      <Route path="/default_pages" component={DefaultPages} />
      <Route path="/documentation" component={Documentation} />
    </div>
  </div>
);
