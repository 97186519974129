import React from 'react';
import { baseURL, currentUserSubject } from '../../../_helpers';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import { Button } from 'reactstrap';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import { Dot } from "react-animated-dots";
import { NavLink } from 'react-router-dom';
import AlternateEmailIcon from 'mdi-react/AlternateEmailIcon';
import Alert from '../../../shared/components/Alert';
import img from '../../../_assets/img/kit.png'
import img1 from '../../../_assets/img/kithome.jpeg'
import img2 from '../../../_assets/img/2.png'
import img4 from '../../../_assets/img/4.jpeg'

class LogIn extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      email_address: "",
      password: "",
      showPassword: false,
      setShowPassword: false,
      channel_type: "portal",
      error: "",
      errorMsg: "",
      role: "",
      id: "",
      user: [],
      statusMessage: "",
      isShowSuccess: false,
      submitted: false,
      alert_error_color: "",
      isLoggedIn: false,
      isChecked: false,
      errorShow: false,
      successShow: false,
      statis: []
    };

  }

  handleChangeEmail = event => {
    this.setState({ email_address: event.target.value });
  };
  handleChangePassword = event => {
    this.setState({ password: event.target.value });
  };


  showPasswordToggle = () => {
    // eslint-disable-next-line
    if (this.state.showPassword == true) {
      this.setState({
        showPassword: false,
      })
    } else {
      this.setState({
        showPassword: true,
      })
    }
  };

  onSubmit = e => {

    e.preventDefault();
    this.setState({ isLoading: true });
    // eslint-disable-next-line
    if (this.state.email_address == "" || this.state.password == "") {
      this.setState({
        isLoading: false,
        error: "Email and password is required",
        errorShow: true,
        submitted: true,

        alert_error_color: "alert alert-danger"
      });
    } else {

      let formData = {
        "email": this.state.email_address,
        "password": this.state.password,
      }
      console.log("DATA", formData)
      this.setState({ isLoading: true });
      axios.post(baseURL + 'login', formData
      ).then((response) => {
        console.log("token", response.data.success);


        // eslint-disable-next-line
        if (response.data.status === true) {
          if (response.data.data.user.group_id === 1) {
            currentUserSubject.next(response.data);
            window.user = response.data;
            this.setState({
              statusMessage: "Login Success! Redirecting....",
              isShowError: true,
              errorShow: false,
              submitted: true,
              isLoggedIn: true
            });
            window.setTimeout(function () {
              window.location.href = "/dashboard";
              this.setState({ isLoading: false });
            }, 100);

          }
          if (response.data.data.user.group_id === 2) {
            currentUserSubject.next(response.data);
            window.user = response.data;
            this.setState({
              statusMessage: "Login Success! Redirecting....",
              isShowError: true,
              errorShow: false,
              submitted: true,
              isLoggedIn: true
            });
            window.setTimeout(function () {
              window.location.href = "/dashboard";
              this.setState({ isLoading: false });
            }, 100);

          }
          if (response.data.data.user.group_id === 4) {
            currentUserSubject.next(response.data);
            window.user = response.data;
            this.setState({
              statusMessage: "Login Success! Redirecting....",
              isShowError: true,
              errorShow: false,
              submitted: true,
              isLoggedIn: true
            });
            window.setTimeout(function () {
              window.location.href = "/dashboard";
              this.setState({ isLoading: false });
            }, 100);

          }

          if (response.data.data.user.group_id === 3) {
            currentUserSubject.next(response.data);
            window.user = response.data;
            this.setState({
              statusMessage: "Login Success! Redirecting....",
              isShowError: true,
              errorShow: false,
              submitted: true,
              isLoggedIn: true
            });
            window.setTimeout(function () {
              window.location.href = "/dashboard";
              this.setState({ isLoading: false });
            }, 100);

          }



          // console.log('data', response.data.user.group_id)
          localStorage.setItem("name", response.data.data.user.first_name + " " + response.data.data.user.last_name)
          localStorage.setItem("user_role", response.data.data.user.group_id)
          localStorage.setItem("token", response.data.data.auth.token)
          // eslint-disable-next-line

          // eslint-disable-next-line
          // else if (response.data.message.role_id == 4) {
          //   window.setTimeout(function () {
          //     window.location.href = "/customers";
          //     this.setState({ isLoading: false });
          //   }, 100);
          // }
          // // eslint-disable-next-line
          // else if (response.data.message.role_id == 2) {
          //   window.setTimeout(function () {
          //     window.location.href = "/customers";
          //     this.setState({ isLoading: false });
          //   }, 100);
          // }
        }
        else {
          console.log("bayoo", response.data)
          this.setState({
            alert_error_color: "alert alert-danger",
            errorShow: true,
            error: response.data.s,
            submitted: true,
            isLoading: false
          }, function () {
            console.log('data', response.data.message)
          });
        }
      }).catch(error => {
        console.log('bayoo', error.response)
        this.setState({
          alert_error_color: "alert alert-danger",
          errorShow: true,
          error: error.response == undefined ? "Check your internet" : error.response.data.status_message,
          submitted: true,
          isLoading: false
        });
        this.setState({ password: "", email_address: "" });
      })


    }
  };




  componentDidMount() {
    this.setState({ isChecked: true });
    axios.get('http://www.geoplugin.net/json.gp').then(res => {
      this.setState({ statusMessage: res.data.status_message, isShowError: false, isLoading: false });
      this.setState({
        students: res.data,
        isLoading: false,
      },
        function () {
          console.log("data", res.data);
        });
    });
  }

  render() {
    const { submitted } = this.state;
    const { showPassword } = this.state;

    const { errorShow, isChecked } = this.state;

    return (



      <div>
        {isChecked && (
          <>
            <div className="elite-login">
              <div className="elite-login-sec">
                <div className="row">
                  <div className="col-md-6 elite-login-left">
                    <div className="carousel-wrap">
                      <div className="owl-carousel">
                        {/* <div className="item">
                          <div className="item-sec">
                            <div className="login_slider_image"><img src={img} /></div>
                            <p className="item-text" style={{ color: "white" }}>Premium Local Advertising Solutions</p>
                          </div>
                        </div> */}

                        <div className="item">
                          <div className="item-sec">
                            <div className="login_slider_image"><img src={img} /></div>
                            {/* <p className="item-text" style={{ color: "white" }}>Discover the New Local</p> */}
                          </div>
                        </div>
                        <div className="item">
                          <div className="item-sec">
                            <div className="login_slider_image"><img src={img1} /></div>
                            {/* <p className="item-text" style={{ color: "white" }}>Geo-Targeted Media Solutions Where You Need Them</p> */}
                          </div>
                        </div>
                        {/* <div className="item">
                          <div className="item-sec">
                            <div className="login_slider_image"><img src={img4} /></div>
                            <p className="item-text" style={{ color: "white" }}>Reach Your Audience At Every Touch Point</p>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 elite-login-right">
                    <h1 className="elite-login-head">Sign in</h1>

                    {submitted ? (
                      <>
                        {this.state.isShowError ? (
                          <>
                            <br />
                            <Alert color="success" className="alert--colored" >
                              <span>
                                {this.state.statusMessage}
                              </span>
                            </Alert>
                          </>
                        ) : null}
                        {errorShow && (
                          <div>
                            <br />
                            <div
                              color="red"
                              style={{ fontSize: "13px", color: "red" }}>
                              {this.state.error}
                            </div>
                            {this.state.errorShow ? (
                              <div>
                                <p
                                  style={{ textAlign: "left", marginLeft: "20px" }}>
                                  {this.state.isLoggedIn ? (
                                    <span>
                                      <Dot>.</Dot>
                                      <Dot>.</Dot>
                                      <Dot>.</Dot>
                                    </span>
                                  ) : null}
                                </p>
                              </div>
                            ) : null}
                          </div>
                        )}
                      </>
                    ) : null}
                    <form className="new_user" id="new_user" onSubmit={this.onSubmit} >
                      <div className="elite-login-form">
                        <div className="elite-form-field">
                          <label htmlFor="user_email">Your email</label>
                          <input className="form-control"
                            placeholder="Enter your email"
                            id="elite-email"
                            required="required"
                            type="email"
                            style={{borderColor: "grey"}}

                            onChange={this.handleChangeEmail}
                            value={this.state.email_address}

                            name="email_address" />
                        </div>
                        <div className="elite-form-field">
                          <label htmlFor="user_email">Password</label>
                          <input placeholder="Enter your password"
                            id="elite-email"
                            className="form-control"
                            required="required"
                            style={{borderColor: "grey"}}
                            value={this.state.password}
                            onChange={this.handleChangePassword}
                            type="password"
                          />
                          
                          <input type="hidden" name="user_timezone" id="user_timezone" />
                          <span toggle="#password-field" className="fa fa-fw fa-eye field-icon toggle-password" />
                          <br /><br />
                        </div>
                        <p className="elite-forgot-pwd" ><a href="javascript:void(0);"
                          data-toggle="modal" data-target="#specialist-forgotModal">
                          {/* Forgot Password? */}
                        </a></p>

                        <button type="submit" className="elite-form-btn"> {
                          this.state.isLoading ? "Please wait..." : "Sign In"}
                        </button>
                        {/* <p className="elite-form-signup">Not a member? <a href="https://accesselitenow.com/">Sign up</a></p>
                    <p className="elite-form-query">Any questions? Contact Us  <a href="tel:833-755-0402">(833) 755-0402</a></p> */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Forgot Password Modal S*/}
            <div className="modal fade" id="specialist-forgotModal">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <form className="login-form js-login-form" id="reset_password_form" action="https://app.accesselitenow.com/password" acceptCharset="UTF-8" method="post"><input name="utf8" type="hidden" defaultValue="✓" /><input type="hidden" name="authenticity_token" defaultValue="guBOExXaEiN8w8q4058tGR4cnmH0HHNW2y4X32eiOmj0XWXdbv+J5C5elYY3jP5GXtN+cC+rBYi8dq4pUVzjXg==" />
                    {/* Modal Header */}
                    <button type="button" className="close specialist-popup-close" data-dismiss="modal">×</button>
                    {/* Modal body */}
                    <div className="modal-body">
                      <h2 className="specialist-popup-title">Forgot Password?</h2>
                      <div className="elite-form-field">
                        <input id="forgot_password_email" required="required" autofocus="autofocus" placeholder="Enter your email" type="email" defaultValue name="user[email]" />
                        {/* <input type="hidden" name="login_path" id="login_path" defaultValue="specialist" /> */}
                        <br></br>
                        <br></br>
                      </div>

                    </div>
                    {/* Modal footer */}
                    <div className="modal-footer justify-content-center" style={{ background: "white" }}>
                      <button type="submit" className="elite-form-btn"> submit</button>
                    </div>
                  </form></div>
              </div>
            </div>
            {/* Forgot Password Modal E*/}
          </>
        )}
      </div>
    );
  }
}

export default LogIn;
