import * as React from 'react';
import '../../_assets/css/file.css';
import axios from 'axios';
import { baseURL } from '../../_helpers';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Survey } from './survey';
import { ROLE, TOKEN } from '../../_helpers/token';
import Select from 'react-select';
import { borderColor } from 'polished';
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import TimePicker from 'react-time-picker/dist/entry.nostyle'
import { confirm } from "../../shared/components/confirm";
import Alert from '../../shared/components/Alert';
import * as moment from 'moment';

export class createSurvey extends React.Component {

    constructor(props) {
        super(props)
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            phone_number: '',
            isLoading: false,
            showModal: false,
            start_date: new Date(),
            end_date: new Date(),
            showError: false,
            isShowError: false,
            showComponent: false,
            hideComponent: false,
        }
    }

    async componentDidMount() {
        const [
            shortResponse] = await Promise.all([
                axios.get(baseURL + 'shortcodes?page_size=1000', { headers: { "Authorization": `Bearer ` + TOKEN } }),
            ]);
        this.setState({
            isLoading: false,
            shortResponse: shortResponse.data.data,
        })
    }


    ShortCodes2() {
        return (this.state.shortResponse && this.state.shortResponse.length > 0 &&
            this.state.shortResponse.map((countyItem, i) =>
                ({ label: countyItem.shortcode, value: countyItem.shortcode_id })))

    }

    onSubmit(e) {
        e.preventDefault();
        let Sdate = moment(this.state.start_date).format("yyyy-MM-DD HH:mm:ss");
        let Edate = moment(this.state.end_date).format("yyyy-MM-DD HH:mm:ss");
        let formData = {
            "name": this.state.name,
            "shortcode_id": this.state.shortcode_id,
            "start_datetime": Sdate,
            "end_datetime": Edate
        }
        console.log("DATA", JSON.stringify(formData))
        this.setState({ isLoading: true });

        axios.post(baseURL + 'surveys', formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        })
            .then((response) => {
                console.log("bayo", response)
                // eslint-disable-next-line
                if (response.data.status == true) {
                    console.log("records", formData)

                    this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false });
                    setTimeout(() => {
                        this.setState({ name: "", isShowError: false, hideComponent: true, showComponent: true, isLoading: false })
                    }, 2000);

                } else {
                    this.setState({ statusMessage: response.data.status_message, isShowError: false, isLoading: false });
                    // window.setTimeout(function () {
                    //     window.location.href = "/session";
                    // }, 2000);
                }

            })
            .catch(error => {
                console.log('bayoo', error.response)
                this.setState({
                    showError: true,
                    isShowError: false,
                    statusMessage: error.response.data.status_message,
                    submitted: true,
                    isLoading: false
                }
                    , function () {
                        // console.log('data', error.response.status_message)
                    });
            });

    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    onSelectChangesShort = value => {
        this.setState({ shortcode_id: value.value.toString() });
    };
    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;

        return (
            <div className="pcoded-main-container">

                {showComponent && (
                    <div>

                        {this.state.showComponent ?
                            <Survey /> : null
                        }

                    </div>
                )}

                {!hideComponent && (
                    <>

                        <Col md={12} lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h4><b>Create Survey</b></h4>
                                        </div>
                                        <div className="col-md-6">
                                            <Link to="/survey">
                                                <Button className="pull-right"
                                                    color="primary" outline>Surveys</Button>
                                            </Link>

                                        </div>
                                    </div>


                                    {this.state.showError ? <div >

                                        <Alert color="danger" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>


                                    </div> : null}<br></br>

                                    {this.state.isShowError ? (
                                        <div>

                                            <Alert color="success" className="alert--colored" icon>
                                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                                </p>
                                            </Alert>

                                        </div>

                                    ) : null}<br></br>
                                    <form className="col-md-8 offset-md-2" onSubmit={this.onSubmit}>

                                        {ROLE === '1' ?
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label className="form-label">Select ShortCodes</label>
                                                    </div>
                                                    <div className="col-md-10">

                                                        <Select
                                                            options={this.ShortCodes2()}
                                                            onChange={this.onSelectChangesShort}
                                                            placeholder="Select Shortcode"
                                                            tabSelectsValue={false}
                                                            value={this.state.onSelectChangesShort}
                                                            className='drop'
                                                        />
                                                    </div>
                                                </div>
                                                <br />
                                            </div>
                                            : null}
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="form-label">Survey Name</label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input id="input" type="text" className="form-control input-md"
                                                        name="name" required placeholder="Enter Survey Name"
                                                        value={this.state.name} onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">

                                            <div className="row">
                                                <div className="col-md-2">
                                                    <Form.Label>Start date</Form.Label>
                                                </div>
                                                <div className="col-md-10 customDatePickerWidth">
                                                    <DatePicker
                                                        selected={this.state.start_date}
                                                        label="DatePicker Label"
                                                        dateFormat="yyyy-MM-dd HH:mm:ss"
                                                        className="form-control"
                                                        showTimeSelect
                                                        timeIntervals={1}
                                                        id="input"
                                                        onChange={value => this.setState({ start_date: value })} />


                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">

                                            <div className="row">
                                                <div className="col-md-2">
                                                    <Form.Label>End date</Form.Label>
                                                </div>
                                                <div className="col-md-10 customDatePickerWidth">
                                                    <DatePicker
                                                        selected={this.state.end_date}
                                                        label="DatePicker Label"
                                                        dateFormat="yyyy-MM-dd HH:mm:ss"
                                                        className="form-control"
                                                        showTimeSelect
                                                        timeIntervals={1}
                                                        id="input"
                                                        onChange={value => this.setState({ end_date: value })} />


                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="form-group">
                                            <br />
                                            <Button type="submit" color="primary" outline className="btn-paypal btn-lg pull-right" >
                                                {this.state.isLoading ? "Please Wait..." : "Submit"}

                                            </Button>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </>
                )
                }


            </div>
        )

    }

}
