import React, { Fragment } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import { Button, Card, CardBody, Col, Form } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { TOKEN } from '../../_helpers/token';
import * as moment from 'moment';
import { Link } from 'react-router-dom';
import { ShortCodes } from '../ShortCode/shortcode';
import CreateService from './createCreate';
import Select from 'react-select';
import { confirm } from "../../shared/components/confirm";
import Alert from '../../shared/components/Alert';


export class Services extends React.Component {
    constructor(props) {
        super(props)
        this._onButtonClick = this._onButtonClick.bind(this);
        this._onButtonClick2 = this._onButtonClick2.bind(this);
        this._onButtonClick3 = this._onButtonClick3.bind(this);
        this.onSubmitUpdates = this.onSubmitUpdates.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);

        this.columns = [
            // {
            //     key: "idx",
            //     TrOnlyClassName: 'tsc',
            //     text: "#",
            //     className: "tsc",
            //     align: "left"
            // },
            {
                key: "offer_name",
                TrOnlyClassName: 'tsc',
                text: "Offer Name",
                className: "tsc",
                align: "left"
            },
            {
                key: "offer_code",
                TrOnlyClassName: 'tsc',
                text: "Offer Code",
                className: "tsc",
                align: "left"
            },
            {
                key: "service_type",
                TrOnlyClassName: 'tsc',
                text: "Service Type",
                className: "tsc",
                align: "left"
            },
            {
                key: "autoresponses_message",
                TrOnlyClassName: 'tsc',
                text: "Autoresponse Message",
                className: "tsc",
                align: "left"
            },
            // {
            //     key: "opt_in_keyword",
            //     TrOnlyClassName: 'tsc',
            //     text: "Opt In KeyWord",
            //     className: "tsc",
            //     // align: "left"
            // },
            {
                key: "mail",
                TrOnlyClassName: 'tsc',
                text: "Status",
                className: "tsc",
                align: "left",
                cell: record => {
                    return (
                        <Fragment className="center" style={{ fontSize: "1em", color: "white" }}>
                            {record.mail === 'Active' ?
                                <span class="badge-success" style={{ borderRadius: "5px", }}>
                                    {record.mail}
                                </ span>
                                : null}

                            {record.mail === 'Inactive' ?
                                <span class="badge-danger" style={{ borderRadius: "5px", }}>
                                    {record.mail}
                                </ span>
                                : null}
                        </Fragment >
                    );
                }
            },

            {
                key: "dates",
                TrOnlyClassName: 'tsc',
                text: "Date Created",
                className: "tsc",
                align: "left"
            },
            {
                key: "action",
                text: "Options",
                TrOnlyClassName: 'cell',
                className: "cell",
                width: 250,
                sortable: false,
                cell: record => {
                    return (
                        <Fragment className="center" >
                            <button className="btn btn-primary btn-sm"
                                title="Edit details"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.onEdit(record) }} >
                                Edit
                            </button>
                            <button className="btn btn-danger btn-sm"
                                title="Delete details"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.handleOnClick(record) }} >
                                Remove
                            </button>
                            {/* <button className=" btn-success"
                                title="Questions details"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.onAnswers(record) }} >
                                <span className="fa fa-trash dt-icon-btn" > Answers </span>
                            </button> */}



                        </Fragment>
                    );
                }
            }
        ];
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            page_size: 10,
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Search...",
                no_data_text: "No data was found",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }

        }
        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            showError: false,
            isShowError: false,
            showComponent: false,
            showModals: false,
            showEdit: false,
            hideComponent: false,
            data: [],
            type: [{
                name: 'ON_DEMAND'
            }, {
                name: 'SUBSCRIPTION'
            }],
        };
    }

    async handleOnClick(record) {
        console.log("hoge!");
        if (await confirm("Are your sure?")) {
            this.onDelete(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }
    componentDidMount() {
        this.getData();

    }

    getData = (queryString = "") => {
        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + TOKEN
            }
        };
        //alert(localStorage.getItem("shortcode_id"))

        let url = baseURL + "services?shortcode_id=" + localStorage.getItem("shortcode_id") + "&&" + queryString
        console.log("query", url);

        axios.all([
            axios.get(url, axiosConfig),

        ]).then(axios.spread((branchResponse) => {

            this.setState({
                admins: branchResponse.data.data,
                isLoading: false,
            },
                function () {
                    var data = [];
                    console.log("BUG", this.state.admins)

                    if (this.state.admins.length > 0 || this.state.admins.length === 0) {

                        for (let i = 0; i < this.state.admins.length; i++) {
                            this.setState({
                                total: branchResponse.data.total
                            })
                            let mail;
                            let mbalance;
                            let phone;
                            let index = { idx: i + 1 }

                            if (this.state.admins[i].status == "1") {
                                mail = { mail: "Active" };
                            } else {
                                mail = { mail: "Inactive" };
                            }


                            if (this.state.admins[i].balance === 0) {
                                mbalance = { mbalance: "0.0" };
                            } else {
                                mbalance = { mbalance: this.state.admins[i].balance };
                            }

                            if (this.state.admins[i].msisdn === null) {
                                phone = { phone: "_" };
                            } else {
                                phone = { phone: this.state.admins[i].msisdn };
                            }

                            let date = { dates: moment.utc(this.state.admins[i].created_at).format('yyyy-MM-DD HH:mm:ss') };
                            data.push(Object.assign(date, mail, phone, index, mbalance, this.state.admins[i]));
                            this.setState({
                                data: data
                            })
                            console.log("bugs", data);

                        }
                    }

                })
        })).catch(error => {
            console.log('bayoo', error.response)

        })
    }

    Accounts() {
        return (this.state.county && (this.state.county.length > 0 || this.state.county.length === 0) &&
            this.state.county.map((countyItem, i) =>
                ({ label: countyItem.account_name, value: countyItem.account_id })))

    }
    onSelectChanges = value => {
        this.setState({ account_id: value.value.toString() });
    };


    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }


    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,
            showEdit: false,
            showModal: false,
        }, function () {
            // localStorage.removeItem("shortcode_id")

        });
    }

    _onButtonClick2() {
        this.setState({
            showComponent: false,
            hideComponent: true,
            showEdit: false,
            showModal: false,
            showModals: true
        });
    }
    _onButtonClick3() {
        this.setState({
            showComponent: false,
            hideComponent: false,
            showModal: false,
            showEdit: false,
            showModals: false
        });
    }

    onEdit(record) {
        this.setState({
            showComponent: false,
            hideComponent: true,
            showEdit: true,
            service_id: record.service_id,
            autoresponses_message: record.autoresponses_message,
            offer_code: record.offer_code,
            offer_name: record.offer_name
        }, function () {
            // localStorage.setItem("question_id", this.state.survey_id)
        });

    }

    onAnswers(record) {
        this.setState({
            showComponent: false,
            hideComponent: true,
            showModal: true,
        }, function () {
            // localStorage.setItem("question_id", this.state.survey_id)
        });

    }


    onDelete(record) {

        console.log("Delete Record", record);
        axios.delete(baseURL + "services/" + record.service_id, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        }).then(response => {
            console.log("true", response.data)
            if (response.data.status) {
                this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false }, function () {

                    window.setTimeout(() => {
                        this.getData();
                        this.setState({ isShowError: false })
                    }, 2000);
                });
            } else {
                this.setState({ statusMessage: response.data.status_message, isShowError: false, showError: true, isLoading: false });
                window.setTimeout(() => {
                    this.getData();
                    this.setState({ showError: false })
                }, 2000);
            }
        }, error => {
            this.getData();
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
        });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmitUpdates(e) {
        e.preventDefault();

        let formData = {
            // "offer_name": this.state.offer_name,
            // "offer_code": this.state.offer_code,
            // "shortcode_id": window.localStorage.getItem("shortcode_id"),
            "autoresponses_message": this.state.autoresponses_message,
            // "opt_in_keyword": this.state.opt_in_keyword,
            // "opt_out_keyword": this.state.opt_out_keyword,
            // "account_id": this.state.account_id,
            // "service_type": this.state.selectedType
        }
        console.log("DATA", JSON.stringify(formData))
        this.setState({ isLoading: true });
        axios.put(baseURL + 'services/' + this.state.service_id, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        })
            .then((response) => {
                console.log('bayoo', response.data)

                if (response.data.status) {

                    this.setState({ statusMessage: response.data.status_message, showError: false, isShowError: true, isLoading: false });
                    setTimeout(() => {
                        this.getData();
                        this.setState({
                            showComponent: false,
                            hideComponent: false,
                            statusMessage: "", showError: false, isShowError: false,
                            showEdit: false,
                            showModal: false,
                        });
                    }, 2000);
                } else {
                    this.getData();
                    this.setState({ statusMessage: response.data.status_message, showError: true, isShowError: false, isLoading: false });
                }
            })
            .catch((error) => {
                console.log('bayoo', error)
                this.setState({ statusMessage: error.response.data.status_message, showError: true, isShowError: false, isLoading: false });
            })
    }


    handleChangeMessage = (event) => {
        this.setState({ selectedMessage: event.target.value }, function () {

        });
        //alert(event.target.value)
    };
    ReactTypes() {
        return (this.state.type && (this.state.type.length > 0 || this.state.type.length === 0) &&
            this.state.type.map((countyItem, i) =>
                ({ label: countyItem.name, value: countyItem.name })))
    }
    handleChangeType = value => {
        this.setState({ selectedType: value.value.toString() });
    };
    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;
        const { showModal } = this.state;
        const { showModals } = this.state;
        const { showEdit } = this.state;
        return (
            <div >
                {!hideComponent && (
                    < >

                        < Col md={12} lg={12} >
                            < Card >
                                <CardBody >
                                    <div className="panel-body" >
                                        <h4><b>List of Services ({localStorage.getItem("shortcode_codename")})</b></h4><br />

                                        <div className="row">
                                            <div className="col-md-6">
                                                <Button color="primary" onClick={this._onButtonClick} outline> ShortCodes
                                            </Button>
                                            </div>
                                            <div className="col-md-6">
                                                <Link to="createService">
                                                    <Button className="pull-right" color="primary
                                                    " onClick={this._onButtonClick2} outline>Create a Service
                                                </Button>
                                                </Link>
                                            </div>
                                        </div>
                                        <br />
                                        {this.state.showError ? <div >

                                            <Alert color="danger" className="alert--colored" icon>
                                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                                </p>
                                            </Alert>


                                        </div> : null}<br></br>

                                        {this.state.isShowError ? (
                                            <div>

                                                <Alert color="success" className="alert--colored" icon>
                                                    <p><span className="bold-text">{this.state.statusMessage}</span>
                                                    </p>
                                                </Alert>

                                            </div>

                                        ) : null}<br></br>
                                        <ReactDatatable
                                            config={this.config}
                                            records={this.state.data}
                                            columns={this.columns}
                                            dynamic={true}
                                            id="tsc"
                                            loading={this.state.isLoading}
                                            total_record={this.state.total}
                                            onChange={this.tableChangeHandler} />
                                    </div>
                                </CardBody>

                            </Card>
                        </Col>
                    </>
                )}
                {showComponent && (
                    < >
                        <ShortCodes />
                    </>
                )}
                {/* {/* 
                // {showModal && (
                //     < >
                //         <Answers />
                //     </>
                // )} */}

                {showModals && (
                    < >
                        <CreateService />
                    </>
                )}
                {showEdit && (
                    <>
                        <Col md={12} lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h4>Edit a Service</h4>
                                        </div>
                                        <div className="col-md-6">
                                            <Button className="pull-right" outline color="primary" onClick={this._onButtonClick3}>
                                                Services
                                       </Button>

                                        </div>
                                    </div>

                                    {this.state.showError ? <div >

                                        <Alert color="danger" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>


                                    </div> : null}<br></br>

                                    {this.state.isShowError ? (
                                        <div>

                                            <Alert color="success" className="alert--colored" icon>
                                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                                </p>
                                            </Alert>

                                        </div>

                                    ) : null}<br></br>


                                    <form className="form" onSubmit={this.onSubmitUpdates}>
                                        <br></br>
                                        {/* <> */}
                                            {/* <div className="col-md-8  offset-2">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-2">

                                                            <label className="form-label">Opt In KeyWord</label>
                                                        </div>
                                                        <div className="col-md-10">

                                                            <input id="input" type="text" className="form-control input-md"
                                                                name="opt_in_keyword" required placeholder="Opt In KeyWord"
                                                                value={this.state.opt_in_keyword} onChange={this.handleChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-8  offset-2">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-2">

                                                            <label className="form-label">Opt Out KeyWord</label>
                                                        </div>
                                                        <div className="col-md-10">

                                                            <input id="input" type="text" className="form-control input-md"
                                                                name="opt_out_keyword" required placeholder="Opt Out KeyWord"
                                                                value={this.state.opt_out_keyword} onChange={this.handleChange} />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </> */}

                                        {/* <div className="col-md-8  offset-2">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label className="form-label">Auto Response Message</label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <input id="input" type="text" maxLength="160"  className="form-control input-md"
                                                            name="autoresponses_message" required placeholder="Auto Response Message"
                                                            value={this.state.autoresponses_message} onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}

                                        <div className="col-md-8 offset-2">
                                            <br />
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">

                                                        <label className="form-label">AutoResponse Message</label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <textarea name="autoresponses_message"

                                                            maxLength="160"
                                                            value = {this.state.autoresponses_message}

                                                            required style={{
                                                                paddingTop: '20px',
                                                                borderRadius: "5px", borderColor: "hsl(0,0%,80%)"
                                                            }}
                                                            onChange={this.handleChange} className="col-md-12"
                                                            placeholder="Hint: Enter AutoResponse Message here"
                                                            id="message" cols="col-md-1" rows="20"></textarea>
                                                    </div>
                                                    {/* <div className="col-md-2">
                                                        <ul id="sms-counter">
                                                            <li>Length: <span class="length"></span></li>
                                                            {/* <li>SMS: <span class="messages"></span></li> */}
                                                          
                                                </div>
                                            </div>
                                            <br />
                                        </div>


                                        
                                        {/* <div className="col-md-8  offset-2">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label className="form-label">Select Type</label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <Select
                                                            options={this.ReactTypes()}
                                                            onChange={this.handleChangeType}
                                                            placeholder="Select Service Type"
                                                            tabSelectsValue={false}
                                                            value={this.state.handleChangeType}
                                                            className='drop'
                                                        />
                                                        <br />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="col-md-8 offset-2 form-group">
                                            <br />
                                            <Button type="submit" color="primary" outline className="btn-paypal btn-lg pull-right" >
                                                {this.state.isLoading ? "Please Wait..." : "Submit"}

                                            </Button> &nbsp;&nbsp;&nbsp;
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </>
                )}
            </div>
        )
    }
}