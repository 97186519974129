import React from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import { Card, CardBody, Col } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { TOKEN } from '../../_helpers/token';
import * as moment from 'moment';

export class Transaction extends React.Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "msisdn",
                TrOnlyClassName: 'tsc',
                text: "Phone Numnber",
                className: "tsc",
                align: "left"
            },
            {
                key: "reference",
                TrOnlyClassName: 'tsc',
                text: "Transaction ID",
                className: "tsc",
                align: "left"
            },
            {
                key: "amount",
                TrOnlyClassName: 'tsc',
                text: "Amount",
                className: "tsc",
                align: "left"
            },
            {
                key: "status",
                TrOnlyClassName: 'tsc',
                text: "Status",
                className: "tsc",
                align: "left"
            },
            {
                key: "transaction_id",
                TrOnlyClassName: 'tsc',
                text: "Code",
                className: "tsc",
                align: "left"
            },
            {
                key: "dates",
                TrOnlyClassName: 'tsc',
                text: "Datecreated",
                className: "tsc",
                align: "left"
            }
        ];
        this.config = {
            key_column: "tsc",
            length_menu: [10,50, 100, 150],
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            page_size: 10,
            show_length_menu: true,
            button: {
                excel: true
            },
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Enter Phone/Email...",
                no_data_text: "No data was found",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                },
           
            }
        }
        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            showError: false,
            isShowError: false,
            showComponent: false,
            hideComponent: false,
            data: [],
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = (queryString = "") => {
        axios.get(baseURL).then(res => {
            this.setState({
                isLoading: true,
            })
            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": TOKEN
                }
            };
            let url = baseURL + "transaction?" + queryString
            console.log("query", url);
            const config = {
                headers: {
                    'Authorization': TOKEN
                }
            };
            console.log("token", TOKEN)
            axios.all([
                axios.get(url, axiosConfig),
                axios.get(baseURL + "count", config),
            ]).then(axios.spread((branchResponse) => {
                this.setState({
                    admins: branchResponse.data,
                    isLoading: false,
                },
                    function () {
                        var data = [];
                        console.log("BUG", this.state.admins)

                        if (this.state.admins.length > 0) {
                            for (let i = 0; i < this.state.admins.length; i++) {
                                this.setState({
                                    total: this.state.admins[0].total_page
                                })
                                let mail;
                                let mbalance;
                                if (this.state.admins[i].email == null) {
                                    mail = { mail: "_" };
                                } else {
                                    mail = { mail: this.state.admins[i].email };
                                }

                                if (this.state.admins[i].balance === 0) {
                                    mbalance = { mbalance: "0.0" };
                                } else {
                                    mbalance = { mbalance: this.state.admins[i].balance };
                                }

                                let mreversed = { mreversed: this.state.admins[i].reversed.toString() };

                                let date = { dates: moment.utc(this.state.admins[i].date_created).format('DD, MMM YYYY HH:mm:ss') };
                                data.push(Object.assign(date, mail, mbalance, mreversed, this.state.admins[i]));
                                this.setState({
                                    data: data
                                })
                                console.log("bugs", data);
                            }
                        } else {
                            this.setState({
                                data: data,
                                isLoading: false,

                            })
                            console.log("bugs", data);
                        }
                    }
                );
            }))
        })

    }

    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }


    render() {
        return (
            <div >
                < >
                    <Col md={12}>
                        <h3>Withdrawal Requests</h3><br />

                    </Col>
                    < Col md={12} lg={12} >
                        < Card >
                            <CardBody >
                                <div className="panel-body" >

                                    {this.state.isShowError ?
                                        <div className="alert alert-success" > {this.state.statusMessage}
                                        </div> : null
                                    }
                                    <ReactDatatable
                                        config={this.config}
                                        records={this.state.data}
                                        columns={this.columns}
                                        dynamic={true}
                                        id="tsc"
                                        loading={this.state.isLoading}
                                        total_record={this.state.total}
                                        onChange={this.tableChangeHandler} />
                                </div>
                            </CardBody>

                        </Card>
                    </Col>
                </>
            </div>
        )
    }
}