import React from 'react';
import '../../_assets/css/file.css';
import { Form } from "react-bootstrap";
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { TOKEN } from '../../_helpers/token';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { BreakingNews } from './broadcast';
import $ from 'jquery'
import jQuery from 'jquery';
import { Link } from 'react-router-dom';
import Alert from '../../shared/components/Alert';
window.$ = window.jQuery = require('jquery')

class EditBreakingNews extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };
    constructor(props) {
        super(props)
        this.state = {
            showComponent: false,
            hideComponent: true,
        };
        this._onButtonClick = this._onButtonClick.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            event_name: '',
            event_description: '',
            start_time: '',
            end_time: '',
            entity_type: '4',
            isLoading: false,
            isShowError: false,
            statusMessage: '',
            applicable_to: '',
            short_code: '',
            selectedEntity: '',
            hideEntitiy: false,
            hideBranch: false,
            transit: '',
            plans: '',
            event_date: '',
            fee: '',
            showError: false,
            type: [{
                name: 'Rating'
            }, {
                name: 'Closed-ended'
            }]
        }
    }

    async componentDidMount() {
        $('#message').countSms('#sms-counter');
        const [
            countyResponse] = await Promise.all([
                axios.get(baseURL + 'shortcodes', { headers: { "Authorization": `Bearer ` + TOKEN } }),
            ]);
        console.log(countyResponse)
        this.setState({
            isLoading: false,
            BreakingNews: countyResponse.data.data,

        })
    }
    handleChangeMessage = (event) => {
        this.setState({ reason: event.target.value }, function () {

        });
        //alert(event.target.value)
    };


    ShortCodes() {
        return (this.state.BreakingNews && this.state.BreakingNews.length > 0 &&
            this.state.BreakingNews.map((countyItem, i) =>
                ({ label: countyItem.shortcode, value: countyItem.shortcode_id })))
    }

    onSelectChanges = value => {
        this.setState({ shortcode_id: value.value.toString() },
            function () {
                fetch(baseURL + 'services?shortcode_id=' + value.value.toString(),
                    { headers: { "Authorization": `Bearer ` + TOKEN } })
                    .then(response => response.json())
                    .then(
                        res => {
                            this.setState({ services: res.data },
                                console.log("bayd", res)
                            )
                        }
                    )

            });
    };

    services() {
        return (this.state.services && (this.state.services.length > 0 || this.state.services.length === 0) &&
            this.state.services.map((countyItem, i) =>
                ({ label: countyItem.offer_name, value: countyItem.service_id })))

    }

    onSelectService = value => {
        this.setState({ service_id: value.value.toString() })
    }

    handleChangeRegion = (event) => {
        this.setState({ selectedPriority: event.target.value });
    };


    handleChangeType = (event) => {
        this.setState({ selectedType: event.target.value });
    };

    onSubmit(e) {
        e.preventDefault();
        this.setState({ isLoading: true });

        let formData = {
            "status": "REJECTED",
            "reject_reason": this.state.reason
        }

        console.log("data", formData)
        axios.put(baseURL + "breakingNews/" + localStorage.getItem("broadcastid")
            , formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        }).then(response => {
            console.log("true", response.data)

            if (response.data.status) {
                this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        // this._onButtonClick3();
                        this._onButtonClick3();

                        this.setState({ reason: "", statusMessage: "", isShowError: false })
                    }, 2000);
                });
            } else {
                this.setState({ statusMessage: response.data.status_message, showError: true, isLoading: false });
                window.setTimeout(() => {
                    this.setState({ showError: false, reason: "" })
                }, 2000)
            }
        }, error => {
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
            window.setTimeout(() => {
                this.getData();
                this.setState({ isShowError: false })
            }, 2000)
        });
    }


    onSelectChange = value => {
        this.setState({ entity_id: value.value.toString() });
    };

    handleChangeEntityChange = (event) => {
        this.setState({ values: event.target.value });
    };

    handleChangeEntityType = (event) => {
        this.setState({ entity_type: event.target.value });
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChangeCountry = (event) => {
        this.setState({ selectedCountry: event.target.value });
    };

    handleChangeCounty = (event) => {
        this.setState({ selectedCounty: event.target.value });
    };

    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,
        });
    }

    changeStatus() {
        this.setState({
            isShowError: false
        });
    }


    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;
        const { hideEntitiy } = this.state;
        const { hideBranch } = this.state;
        const { t } = this.props;

        return (
            <div>
                {showComponent && (
                    <div>
                        {this.state.showComponent ?
                            <BreakingNews /> : null
                        }
                    </div>
                )}

                {!hideComponent && (
                    <>
                        <Col md={12} lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h4><b>Reject Breaking News</b></h4>
                                        </div>
                                        <div className="col-md-6">
                                            <Link to="breakingNews">
                                                <Button className="pull-right" outline color="primary" onClick={this._onButtonClick}>
                                                    Breaking News
                                            </Button>
                                            </Link>

                                        </div>
                                    </div>

                                    {this.state.showError ? <div >

                                        <Alert color="danger" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>


                                    </div> : null}<br></br>

                                    {this.state.isShowError ? (
                                        <div>

                                            <Alert color="success" className="alert--colored" icon>
                                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                                </p>
                                            </Alert>

                                        </div>

                                    ) : null}<br></br>
                                    <form className="form" onSubmit={this.onSubmit}>
                                        <br /><br />

                                        <div className="col-md-8 offset-2">
                                            <br />
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">

                                                        <label className="form-label">Rejection Message</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <textarea name="message"
                                                            maxLength="160" required style={{
                                                                paddingTop: '20px',
                                                                borderRadius: "5px", borderColor: "hsl(0,0%,80%)"
                                                            }}
                                                            onChange={this.handleChangeMessage} className="col-md-12"
                                                            value={this.state.reason}
                                                            placeholder="Hint: Enter Rejection Message here"
                                                            id="message" cols="col-md-1" rows="20"></textarea>
                                                        <br /><br />
                                                        <Button type="submit" color="primary" outline className="btn-paypal btn-lg pull-right" >
                                                            {this.state.isLoading ? "Please Wait..." : "Submit"}

                                                        </Button> &nbsp;&nbsp;&nbsp;
                                                    </div>
                                                    <div className="col-md-2">
                                                        <ul id="sms-counter">
                                                            <li>Length: <span class="length"></span></li>
                                                            {/* <li>SMS: <span class="messages"></span></li> */}
                                                            <li>Per Message: <span class="remaining"></span>/<span class="per_message"></span></li>
                                                            {/* <li>Remaining: </li> */}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                        </div>
                                        <div className="col-md-8 offset-2 ">

                                        </div>

                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </>
                )}
            </div>
        )
    }
}
export default withTranslation('common')(EditBreakingNews);
