import React from 'react';
import '../../_assets/css/file.css';
import { Form } from "react-bootstrap";
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { TOKEN } from '../../_helpers/token';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Answers } from './answers';
import Alert from '../../shared/components/Alert';

class CreateAnswers extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };
    constructor(props) {
        super(props)
        this.state = {
            showComponent: false,
            hideComponent: true,
        };
        this._onButtonClick = this._onButtonClick.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            event_name: '',
            event_description: '',
            start_time: '',
            end_time: '',
            entity_type: '4',
            isLoading: false,
            isShowError: false,
            statusMessage: '',
            applicable_to: '',
            short_code: '',
            selectedEntity: '',
            hideEntitiy: false,
            hideBranch: false,
            transit: '',
            plans: '',
            event_date: '',
            fee: '',
            showError: false,
            type: [{
                name: 'Rating'
            }, {
                name: 'Closed-ended'
            }],

            priority: [{
                name: '1'
            }, {
                name: '2'
            },
            {
                name: '3'
            },
            {
                name: '4'
            },
            {
                name: '5'
            },
            {
                name: '6'
            },
            {
                name: '7'
            },
            {
                name: '8'
            },
            {
                name: '9'
            },
            {
                name: '10'
            }],
            avenue: '',
            assigned_to: '',
            selectedCounty: 'Select County/State Name',
        }
    }

    handleChangeRegion = (event) => {
        this.setState({ selectedPriority: event.target.value });
    };


    handleChangeType = (event) => {
        this.setState({ selectedType: event.target.value });
    };

    onSubmit(e) {
        e.preventDefault();
        let ttl_new = (this.state.ttl * 1000)

        let formData = {
            "priority": this.state.selectedPriority,
            "question_id": localStorage.getItem("question_id"),
            "acceptable_answers": this.state.acceptable_answers,
            "answer": this.state.answer
        }
        console.log("DATA", JSON.stringify(formData))
        this.setState({ isLoading: true });
        axios.post(baseURL + 'answer', formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        })
            .then((response) => {
                console.log('bayoo', response.data)

                if (response.data.status) {

                    this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false }, function () {
                        window.setTimeout(() => {
                            this._onButtonClick();
                            this.setState({ isShowError: false })
                        }, 2000);
                    });
                    // window.setTimeout(function () {

                } else {
                    this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false }, function () {
                        window.setTimeout(() => {
                            this._onButtonClick();
                            this.setState({ isShowError: false })
                        }, 2000);
                    });

                }
            })
            .catch((error) => {
                console.log('bayoo', error)
                this.setState({ statusMessage: error.response.data.status_message, showError: true, isShowError: false, isLoading: false });
            })
    }


    onSelectChange = value => {
        this.setState({ entity_id: value.value.toString() });
    };

    handleChangeEntityChange = (event) => {
        this.setState({ values: event.target.value });
    };

    handleChangeEntityType = (event) => {
        this.setState({ entity_type: event.target.value });
    };
    handleChangeApplicable = (event) => {
        this.setState({
            applicable_to: event.target.value,
        });
        if (event.target.value === 'entity') {

            this.setState({
                hideEntitiy: true,
                hideBranch: false,
            });
        } else if (event.target.value === 'branch') {
            this.setState({
                hideBranch: true,
                hideEntitiy: false,
            });

        }
        else if (event.target.value === 'class') {
            this.setState({
                hideBranch: false,
                hideEntitiy: false,
            });

        }
        else if (event.target.value === 'stream') {
            this.setState({
                hideBranch: false,
                hideEntitiy: false,
            });

        }
    };
    handleChangeTransit = (event) => {
        this.setState({ transit: event.target.value });
    };
    handleChangeFee = (event) => {
        this.setState({ fee: event.target.value });
    };
    handleChangePlans = (event) => {
        this.setState({ plans: event.target.value });
    };
    handleChangeScope = (event) => {
        this.setState({ event_scope: event.target.value });
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChangeCountry = (event) => {
        this.setState({ selectedCountry: event.target.value });
    };

    handleChangeCounty = (event) => {
        this.setState({ selectedCounty: event.target.value });
    };

    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,
        });
    }

    changeStatus() {
        this.setState({
            isShowError: false
        });
    }


    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;
        const { hideEntitiy } = this.state;
        const { hideBranch } = this.state;
        const { t } = this.props;

        return (
            <div>
                {showComponent && (
                    <div>
                        {this.state.showComponent ?
                            <   Answers /> : null
                        }
                    </div>
                )}

                {!hideComponent && (
                    <>
                        <Col md={12} lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h4><b>Create a Answer</b></h4>
                                        </div>
                                        <div className="col-md-6">
                                            <Button className="pull-right" outline color="primary" onClick={this._onButtonClick}>
                                                Answers
                                            </Button>

                                        </div>
                                    </div>


                                    {this.state.showError ? <div >

                                        <Alert color="danger" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>


                                    </div> : null}<br></br>

                                    {this.state.isShowError ? (
                                        <div>

                                            <Alert color="success" className="alert--colored" icon>
                                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                                </p>
                                            </Alert>

                                        </div>

                                    ) : null}<br></br>
                                    <form className="form" onSubmit={this.onSubmit}>

                                        {/* <div className="col-md-8 offset-2">

                                            <Form.Group className="Focus-line" >
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <Form.Label>Priority</Form.Label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <Form.Control
                                                            as="select"
                                                            className="form-control input-md"
                                                            placeholder="Select Priority"
                                                            value={this.state.selectedPriority}
                                                            onChange={this.handleChangeRegion}
                                                            id="county_name"
                                                            option={this.selectedPriority}>
                                                            <option>Select Priority</option>
                                                            {
                                                                this.state.priority && this.state.priority.length > 0 &&
                                                                this.state.priority.map((regionItem, i) =>
                                                                    <option key={i}
                                                                        value={regionItem.name}>{regionItem.name}</option>)
                                                            }
                                                        </Form.Control>
                                                    </div>
                                                </div> */}

                                            {/* </Form.Group>
                                        </div> */}

                                        <div className="col-md-8 offset-2">

                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label className="form-label">Answer</label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <input id="input" type="text" className="form-control input-md"
                                                            name="answer" required placeholder="Enter Answer"
                                                            value={this.state.answer} onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8 offset-2">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label className="form-label">Acceptable Answer</label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <input id="input" type="text" className="form-control" name="acceptable_answers"
                                                            required placeholder="Acceptable Answer"
                                                            value={this.state.acceptable_answers}
                                                            onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8 offset-2 form-group">
                                            <br />
                                            <Button type="submit" color="primary" outline className="btn-paypal btn-lg pull-right" >
                                                {this.state.isLoading ? "Please Wait..." : "Submit"}

                                            </Button> &nbsp;&nbsp;&nbsp;
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </>
                )}
            </div>
        )
    }
}
export default withTranslation('common')(CreateAnswers);
