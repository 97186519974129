import React from 'react';
import '../../_assets/css/file.css';
import { Form } from "react-bootstrap";
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { TOKEN } from '../../_helpers/token';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import $ from 'jquery'
import jQuery from 'jquery';
import { Link } from 'react-router-dom';
import { Users } from '../users/Users';
import Alert from '../../shared/components/Alert';

window.$ = window.jQuery = require('jquery')

class AddUser extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };
    constructor(props) {
        super(props)
        this.state = {
            showComponent: false,
            hideComponent: true,
        };
        this._onButtonClick = this._onButtonClick.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            event_name: '',
            event_description: '',
            start_time: '',
            end_time: '',
            entity_type: '4',
            isLoading: false,
            isShowError: false,
            statusMessage: '',
            applicable_to: '',
            short_code: '',
            selectedEntity: '',
            hideEntitiy: false,
            hideBranch: false,
            transit: '',
            plans: '',
            event_date: '',
            fee: '',
            showError: false,
            type: [{
                name: 'Rating'
            }, {
                name: 'Closed-ended'
            }]
        }
    }

    async componentDidMount() {
        const [
            shortResponse, groupResponse] = await Promise.all([
                axios.get(baseURL + 'shortcodes?page_size=1000', { headers: { "Authorization": `Bearer ` + TOKEN } }),
                axios.get(baseURL + 'groups', { headers: { "Authorization": `Bearer ` + TOKEN } }),
            ]);
        console.log(groupResponse)
        this.setState({
            isLoading: false,
            shortResponse: shortResponse.data.data,
            groupResponse: groupResponse.data.data
        })
    }
    handleChangeMessage = (event) => {
        this.setState({ selectedMessage: event.target.value }, function () {
        });
        //alert(event.target.value)
    };


    ShortCodes() {
        return (this.state.BreakingNews && this.state.BreakingNews.length > 0 &&
            this.state.BreakingNews.map((countyItem, i) =>
                ({ label: countyItem.account_name, value: countyItem.account_id })))

    }

    ShortCodes2() {
        return (this.state.shortResponse && this.state.shortResponse.length > 0 &&
            this.state.shortResponse.map((countyItem, i) =>
                ({ label: countyItem.shortcode, value: countyItem.shortcode_id })))

    }

    onSelectChangesShort = value => {
        this.setState({ shortcode_id: value.value.toString() });
    };

    onSelectChanges = value => {
        this.setState({ account_id: value.value.toString() });
    };

    services() {
        return (this.state.groupResponse && (this.state.groupResponse.length > 0 || this.state.groupResponse.length === 0) &&
            this.state.groupResponse.map((countyItem, i) =>
                ({ label: countyItem.name, value: countyItem.id })))
    }

    onSelectService = value => {
        this.setState({ role_id: value.value.toString() })
    }

    handleChangeRegion = (event) => {
        this.setState({ selectedPriority: event.target.value });
    };


    handleChangeType = (event) => {
        this.setState({ selectedType: event.target.value });
    };

    onSubmit(e) {
        e.preventDefault();
        let ttl_new = (this.state.ttl * 1000)

        let formData =
        {
            "first_name": this.state.first_name,
            "last_name": this.state.last_name,
            "username": this.state.username,
            "email": this.state.email,
            "msisdn": this.state.msisdn,
            "shortcode_id": this.state.shortcode_id,
            "password": this.state.password,
            "group_id": this.state.role_id
        }



        console.log("DATA", JSON.stringify(formData))
        this.setState({ isLoading: true });
        axios.post(baseURL + 'users', formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            },
        })
            .then((response) => {
                console.log('bayoo', response.data)

                if (response.data.status) {
                    this.setState({ statusMessage: response.data.status_message, showError: false, isShowError: true, isLoading: false });
                    // window.setTimeout(function () {
                    //     window.location.reload();
                    // }, 2000);
                    window.setTimeout(() => {
                        // this._onButtonClick();
                        this.setState({ isShowError: false, first_name: "", last_name: "",username: "",msisdn: "", email: "" })
                    }, 2000);
                } else {
                    this.setState({ statusMessage: response.data.status_message, showError: true, isShowError: false, isLoading: false });
                    window.setTimeout(() => {
                        // this._onButtonClick();
                        this.setState({ showError: false })
                    }, 2000);
                }
            })
            .catch((error) => {
                console.log('bayoo', error)
                this.setState({ statusMessage: error.response.data.status_message, showError: true, isShowError: false, isLoading: false });
            })
    }


    onSelectChange = value => {
        this.setState({ entity_id: value.value.toString() });
    };

    handleChangeEntityChange = (event) => {
        this.setState({ values: event.target.value });
    };

    handleChangeEntityType = (event) => {
        this.setState({ entity_type: event.target.value });
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChangeCountry = (event) => {
        this.setState({ selectedCountry: event.target.value });
    };

    handleChangeCounty = (event) => {
        this.setState({ selectedCounty: event.target.value });
    };

    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true

        });
    }
    changeStatus() {
        this.setState({
            isShowError: false
        });
    }


    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;
        const { hideEntitiy } = this.state;
        const { hideBranch } = this.state;
        const { t } = this.props;

        return (
            <div>
                {showComponent && (
                    <div>
                        {this.state.showComponent ?
                            <Users /> : null
                        }
                    </div>
                )}

                {!hideComponent && (
                    <>
                        <Col md={12} lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h4><b>Create User</b></h4>
                                        </div>
                                        <div className="col-md-6">
                                            <Link to="users">
                                                <Button className="pull-right" outline color="primary" onClick={this._onButtonClick}>
                                                    Users
                                            </Button>
                                            </Link>

                                        </div>
                                    </div>

                                    {this.state.showError ? <div >

                                        <Alert color="danger" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>


                                    </div> : null}<br></br>

                                    {this.state.isShowError ? (
                                        <div>

                                            <Alert color="success" className="alert--colored" icon>
                                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                                </p>
                                            </Alert>

                                        </div>

                                    ) : null}<br></br>
                                    <form className="form" onSubmit={this.onSubmit}>
                                        <br /><br />
                                        <div className="col-md-8 offset-2">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label className="form-label">FirstName</label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <input id="input" type="text" className="form-control" name="first_name"
                                                            required placeholder="FirstName"
                                                            value={this.state.first_name}
                                                            onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8 offset-2">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label className="form-label">LastName</label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <input id="input" type="text" className="form-control" name="last_name"
                                                            required placeholder="LastName"
                                                            value={this.state.last_name}
                                                            onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8 offset-2">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label className="form-label">Username</label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <input id="input" type="text" className="form-control" name="username"
                                                            required placeholder="Username"
                                                            value={this.state.username}
                                                            onChange={this.handleChange} />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        {/* <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-label">Select ShortCodes</label>
                                      
                                        <br />
                                    </div>
                                </div> */}

                                        <div className="col-md-8 offset-2">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label className="form-label">Group</label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <Select
                                                            options={this.services()}
                                                            onChange={this.onSelectService}
                                                            placeholder="Select Group"
                                                            tabSelectsValue={false}
                                                            value={this.state.onSelectService}
                                                            className='drop'
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-md-8 offset-2">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label className="form-label">Select ShortCodes</label>
                                                    </div>
                                                    <div className="col-md-10">

                                                        <Select
                                                            options={this.ShortCodes2()}
                                                            onChange={this.onSelectChangesShort}
                                                            placeholder="Select Shortcode"
                                                            tabSelectsValue={false}
                                                            value={this.state.onSelectChangesShort}
                                                            className='drop'
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-8 offset-2">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label className="form-label">Phone No.</label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <input id="input" type="text" className="form-control" name="msisdn"
                                                            required placeholder="Enter Phone"
                                                            value={this.state.msisdn}
                                                            onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-8 offset-2">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label className="form-label">Email</label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <input id="input" type="email" className="form-control" name="email"
                                                            required placeholder="Enter Emai;"
                                                            value={this.state.email}
                                                            onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8 offset-2 form-group">
                                            <br />
                                            <Button type="submit" color="primary" outline className="btn-paypal btn-lg pull-right" >
                                                {this.state.isLoading ? "Please Wait..." : "Submit"}

                                            </Button> &nbsp;&nbsp;&nbsp;
                                        </div>

                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </>
                )
                }
            </div >
        )
    }
}
export default withTranslation('common')(AddUser);
